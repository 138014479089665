import { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence, useDragControls } from 'framer-motion';
import { Plane, Calendar, MapPin, Users, CreditCard, Map, Text, ListTodo, LuggageIcon   } from 'lucide-react';
import './FloatingMenu.scss';
import { CardName } from '../../types/tools';
import DateCityPicker from './airline/date-pickers';
import DateCityPickerCode from './airline/date-pickers code';
import ButtonHeader from './selectors/buttons-header';
import ButtonPlain from './selectors/buttons-plain';
import Metrics2Col from './selectors/metrics-2col';
import Terms2Buttons from './selectors/terms-2buttons';
import ConversationHistory from './selectors/conversation-history';
import QuickGuide from './selectors/quick-guide';
import InformationSummary from './selectors/information-summary';
import FlightTracking from './selectors/flight-tracking';
import FAQ from './selectors/faq';
import LocationMap from './selectors/location-map';
import PersonalOrganizer from './selectors/personal-organizer';
import BaggageOptions from './airline/baggage-options';

interface MenuCard {
  icon: JSX.Element;
  title: string;
  content: string;
}

interface FloatingMenuProps {
  cardData: { [key: string]: any };
}

export function FloatingMenu({ cardData }: FloatingMenuProps) {
  const [activeCard, setActiveCard] = useState<number | null>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const dragControls = useDragControls();
  const [isDragging, setIsDragging] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    console.log('Received cardData:', cardData);

    // Obtener la última card actualizada
    const lastUpdatedCard = Object.keys(cardData).reduce((latest, cardName) => {
      if (!cardData[cardName]) return latest;
      
      // Si no hay card previa o la card actual tiene datos, actualizar
      if (!latest || cardData[cardName]) {
        return cardName;
      }
      return latest;
    }, null as string | null);

    // Actualizar activeCard basado en la última card
    if (lastUpdatedCard) {
      switch (lastUpdatedCard) {
        case CardName.FLIGHT_DATES:
          setActiveCard(0);
          break;
        case CardName.FLIGHT_DATES_CODE:
          setActiveCard(2);
          break;
        case CardName.CHECKED_BAGS:
          setActiveCard(1);
          break;
        case CardName.PERSONAL_ORGANIZER:
          setActiveCard(4);
          break;
        // Añadir más casos según sea necesario
        default:
          setActiveCard(null);
      }
    }
  }, [cardData]);

  const [isExpanded, setIsExpanded] = useState(false);

  // Log activeCard whenever it changes
  useEffect(() => {
    console.log('Active card changed:', activeCard);
  }, [activeCard]);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const cards: MenuCard[] = [
    // { icon: <Plane className="w-6 h-6" />, title: 'Destino', content: '¿A dónde te gustaría volar?' },
    { icon: <Calendar className="w-6 h-6" />, title: 'Fechas', content: '¿Cuándo planeas viajar?' },
    { icon: <LuggageIcon className="w-6 h-6" />, title: 'Equipaje', content: 'Opciones de equipaje' },
    // { icon: <MapPin className="w-6 h-6" />, title: 'Origen', content: '¿Desde dónde partirás?' },
    // { icon: <Users className="w-6 h-6" />, title: 'Pasajeros', content: '¿Cuántas personas viajarán?' },
    // { icon: <Map className="w-6 h-6" />, title: 'Origen', content: '¿Desde dónde partirás?' },
    // { icon: <ListTodo className="w-6 h-6" />, title: 'Organizador', content: '¿Qué planes tienes?' },
    // { icon: <MapPin className="w-6 h-6" />, title: 'Pago', content: '¿Cómo te gustaría pagar?' },
    // { icon: <Text className="w-6 h-6" />, title: 'Pago', content: '¿Cómo te gustaría pagar?' },

    // { icon: <CreditCard className="w-6 h-6" />, title: 'Pago', content: '¿Cómo te gustaría pagar?' },
    // { icon: <CreditCard className="w-6 h-6" />, title: 'Pago', content: '¿Cómo te gustaría pagar?' },
    // { icon: <CreditCard className="w-6 h-6" />, title: 'Pago', content: '¿Cómo te gustaría pagar?' },
    // { icon: <CreditCard className="w-6 h-6" />, title: 'Pago', content: '¿Cómo te gustaría pagar?' },
  ];

  const handleCardToggle = (index: number) => {
    setActiveCard(activeCard === index ? null : index);
    setIsExpanded(true);
  };

  const scrollButtons = (direction: 'left' | 'right') => {
    if (buttonContainerRef.current) {
      const container = buttonContainerRef.current;
      const scrollAmount = 200; // Cantidad fija de scroll
      
      if (direction === 'left') {
        container.scrollBy({
          left: -scrollAmount,
          behavior: 'smooth'
        });
      } else {
        container.scrollBy({
          left: scrollAmount,
          behavior: 'smooth'
        });
      }
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const container = e.currentTarget;
    setScrollPosition(container.scrollLeft);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const container = e.currentTarget;
    container.style.scrollBehavior = 'auto';
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    const container = e.currentTarget;
    container.style.scrollBehavior = 'smooth';
  };

  useEffect(() => {
    const existingData = sessionStorage.getItem('cardData');
    const parsedData = existingData ? JSON.parse(existingData) : {};

    Object.entries(cardData).forEach(([cardName, newData]) => {
      if (newData) {
        parsedData[cardName] = {
          ...(parsedData[cardName] || {}),
          ...newData
        };
      }
    });

    sessionStorage.setItem('cardData', JSON.stringify(parsedData));
  }, [cardData]);

  return (
    <div className="floating-menu">
      <div className="menu-container">
        {!isMobile && (
          <div className="scroll-buttons-container">
            <motion.button 
              className="scroll-button left"
              onClick={() => scrollButtons('left')}
              initial={{ opacity: 0 }}
              animate={{ opacity: scrollPosition > 0 ? 1 : 0 }}
            >
              ←
            </motion.button>
            
            <motion.button 
              className="scroll-button right"
              onClick={() => scrollButtons('right')}
              initial={{ opacity: 0 }}
              animate={{ 
                opacity: buttonContainerRef.current && 
                  scrollPosition < buttonContainerRef.current.scrollWidth - buttonContainerRef.current.clientWidth 
                  ? 1 : 0 
              }}
            >
              →
            </motion.button>
          </div>
        )}

        <div 
          className="button-container"
          ref={buttonContainerRef}
          onScroll={handleScroll}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onMouseEnter={() => setIsExpanded(true)}
          onMouseLeave={() => !activeCard && setIsExpanded(false)}
        >
          <motion.div
            className="button-stack"
            initial="collapsed"
            animate={isExpanded ? "expanded" : "collapsed"}
            drag="x"
            dragControls={dragControls}
            dragConstraints={buttonContainerRef}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            dragElastic={0.1}
            dragMomentum={false}
          >
            {cards.map((card, index) => (
              <motion.button
                key={index}
                className={`menu-button ${activeCard === index ? 'active' : ''}`}
                onClick={() => handleCardToggle(index)}
                aria-label={card.title}
                variants={{
                  collapsed: { 
                    x: index === 0 ? 0 : -40,
                    opacity: index === 0 ? 1 : 0,
                    scale: index === 0 ? 1 : 0.8 
                  },
                  expanded: { 
                    x: index * 10,
                    opacity: 1,
                    scale: 1 
                  }
                }}
                transition={{ duration: 0.2, delay: index * 0.05 }}
              >
                {card.icon}
              </motion.button>
            ))}
          </motion.div>
        </div>
        <div className="content-container">
          <AnimatePresence mode="wait">
            {activeCard !== null && (
              <motion.div
                key={activeCard}
                className="content-card"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 20 }}
                transition={{ duration: 0.2 }}
              >
                {activeCard === 0 ? (
                  <DateCityPicker
                    departureDate={cardData[CardName.FLIGHT_DATES]?.departure_date}
                    returnDate={cardData[CardName.FLIGHT_DATES]?.return_date}
                    origin={cardData[CardName.FLIGHT_DATES]?.origin_city}
                    destination={cardData[CardName.FLIGHT_DATES]?.destination_city}
                  />
                ) : activeCard === 5 ? (
                  <DateCityPickerCode
                    departureDate={cardData[CardName.FLIGHT_DATES]?.departure_date}
                    returnDate={cardData[CardName.FLIGHT_DATES]?.return_date}
                    origin={cardData[CardName.FLIGHT_DATES]?.origin_city}
                    destination={cardData[CardName.FLIGHT_DATES]?.destination_city}
                    originIATA={cardData[CardName.FLIGHT_DATES]?.origin_iata}
                    destinationIATA={cardData[CardName.FLIGHT_DATES]?.destination_iata}
                  />
                ) : activeCard === 1 ? (
                  <BaggageOptions 
                    checkedBag={cardData[CardName.CHECKED_BAGS]?.checkedBag}
                  />
                ) : activeCard === 4 ? (
                  <PersonalOrganizer
                    title={cardData[CardName.PERSONAL_ORGANIZER]?.title}
                    description={cardData[CardName.PERSONAL_ORGANIZER]?.description}
                    tasks={cardData[CardName.PERSONAL_ORGANIZER]?.tasks}
                    onAddTask={cardData[CardName.PERSONAL_ORGANIZER]?.onAddTask}
                    onUpdateTask={cardData[CardName.PERSONAL_ORGANIZER]?.onUpdateTask}
                    onDeleteTask={cardData[CardName.PERSONAL_ORGANIZER]?.onDeleteTask}
                    onToggleTask={cardData[CardName.PERSONAL_ORGANIZER]?.onToggleTask}
                  />
                ) : activeCard === 3 ? (
                  <ButtonPlain
                    title={cardData[CardName.BUTTONS_PLAIN]?.title}
                    description={cardData[CardName.BUTTONS_PLAIN]?.description}
                    buttons={cardData[CardName.BUTTONS_PLAIN]?.buttons}
                  />
                ) : activeCard === 10 ? (
                  <Metrics2Col
                    cardTitle={cardData[CardName.METRICS_2COL]?.cardTitle}
                    metrics={cardData[CardName.METRICS_2COL]?.metrics}
                  />
                ) : activeCard === 6 ? (
                  <Terms2Buttons
                    title={cardData[CardName.TERMS_2BUTTONS]?.title}
                    content={cardData[CardName.TERMS_2BUTTONS]?.content}
                    cancelText={cardData[CardName.TERMS_2BUTTONS]?.cancelText}
                    acceptText={cardData[CardName.TERMS_2BUTTONS]?.acceptText}
                    onCancel={cardData[CardName.TERMS_2BUTTONS]?.onCancel}
                    onAccept={cardData[CardName.TERMS_2BUTTONS]?.onAccept}
                  />
                ) : activeCard === 7 ? (
                  <ConversationHistory
                    title={cardData[CardName.CONVERSATION_HISTORY]?.title}
                    description={cardData[CardName.CONVERSATION_HISTORY]?.description}
                    conversations={cardData[CardName.CONVERSATION_HISTORY]?.conversations}
                    emptyMessage={cardData[CardName.CONVERSATION_HISTORY]?.emptyMessage}
                    deleteButtonText={cardData[CardName.CONVERSATION_HISTORY]?.deleteButtonText}
                    confirmDeleteText={cardData[CardName.CONVERSATION_HISTORY]?.confirmDeleteText}
                    onDelete={cardData[CardName.CONVERSATION_HISTORY]?.onDelete}
                    dateFormat={cardData[CardName.CONVERSATION_HISTORY]?.dateFormat}
                  />
                ) : activeCard === 8 ? (
                  <QuickGuide
                    title={cardData[CardName.QUICK_GUIDE]?.title}
                    description={cardData[CardName.QUICK_GUIDE]?.description}
                    commands={cardData[CardName.QUICK_GUIDE]?.commands}
                    emptyMessage={cardData[CardName.QUICK_GUIDE]?.emptyMessage}
                    categorized={cardData[CardName.QUICK_GUIDE]?.categorized}
                    onCommandSelect={cardData[CardName.QUICK_GUIDE]?.onCommandSelect}
                    showIcons={cardData[CardName.QUICK_GUIDE]?.showIcons}
                  />
                ) : activeCard === 9 ? (
                  <InformationSummary
                    title={cardData[CardName.INFORMATION_SUMMARY]?.title}
                    description={cardData[CardName.INFORMATION_SUMMARY]?.description}
                    infoPoints={cardData[CardName.INFORMATION_SUMMARY]?.infoPoints}
                    emptyMessage={cardData[CardName.INFORMATION_SUMMARY]?.emptyMessage}
                    editButtonText={cardData[CardName.INFORMATION_SUMMARY]?.editButtonText}
                    confirmButtonText={cardData[CardName.INFORMATION_SUMMARY]?.confirmButtonText}
                    confirmEditText={cardData[CardName.INFORMATION_SUMMARY]?.confirmEditText}
                    onEdit={cardData[CardName.INFORMATION_SUMMARY]?.onEdit}
                    onConfirm={cardData[CardName.INFORMATION_SUMMARY]?.onConfirm}
                    showIcons={cardData[CardName.INFORMATION_SUMMARY]?.showIcons}
                    showTimestamp={cardData[CardName.INFORMATION_SUMMARY]?.showTimestamp}
                    categorized={cardData[CardName.INFORMATION_SUMMARY]?.categorized}
                  />
                ) : activeCard === 11 ? (
                  <FlightTracking
                    title={cardData[CardName.FLIGHT_TRACKING]?.title}
                    description={cardData[CardName.FLIGHT_TRACKING]?.description}
                    placeholder={cardData[CardName.FLIGHT_TRACKING]?.placeholder}
                    searchButtonText={cardData[CardName.FLIGHT_TRACKING]?.searchButtonText}
                    emptyMessage={cardData[CardName.FLIGHT_TRACKING]?.emptyMessage}
                    flightStatus={cardData[CardName.FLIGHT_TRACKING]?.flightStatus}
                    onSearch={cardData[CardName.FLIGHT_TRACKING]?.onSearch}
                    showDetails={cardData[CardName.FLIGHT_TRACKING]?.showDetails}
                  />
                ) : activeCard === 12 ? (
                  <FAQ
                    title={cardData[CardName.FAQ]?.title}
                    description={cardData[CardName.FAQ]?.description}
                    faqs={cardData[CardName.FAQ]?.faqs}
                    emptyMessage={cardData[CardName.FAQ]?.emptyMessage}
                  />
                ) : activeCard === 5 ? (
                  <LocationMap
                    title={cardData[CardName.LOCATION_MAP]?.title}
                    description={cardData[CardName.LOCATION_MAP]?.description}
                    defaultLocation={cardData[CardName.LOCATION_MAP]?.defaultLocation}
                    defaultZoom={cardData[CardName.LOCATION_MAP]?.defaultZoom}
                    showCurrentLocation={cardData[CardName.LOCATION_MAP]?.showCurrentLocation}
                    onLocationSelect={cardData[CardName.LOCATION_MAP]?.onLocationSelect}
                  />
                ) : activeCard === 13 ? (
                  <ButtonHeader
                    headerImage={cardData[CardName.BUTTONS_HEADER]?.headerImage}
                    title={cardData[CardName.BUTTONS_HEADER]?.title}
                    description={cardData[CardName.BUTTONS_HEADER]?.description}
                    buttons={cardData[CardName.BUTTONS_HEADER]?.buttons}
                  />
                ) : (
                  <>
                    <h2>{cards[activeCard].title}</h2>
                    <p>{cards[activeCard].content}</p>
                  </>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
} 