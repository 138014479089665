import { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import { Button } from "../../../components/ui/button";
import { ChevronDown, ChevronUp } from 'lucide-react';

interface FAQItem {
  id: string;
  question: string;
  answer: string;
}

interface FAQProps {
  title?: string;
  description?: string;
  faqs?: FAQItem[];
  emptyMessage?: string;
}

export default function FAQ({
  title = "Preguntas Frecuentes",
  description = "Encuentra respuestas a las preguntas más comunes sobre el asistente de voz.",
  faqs = [
    { id: "1", question: "¿Cómo uso el asistente de voz?", answer: "Para usar el asistente de voz, simplemente di 'Hola asistente' seguido de tu pregunta o comando." },
    { id: "2", question: "¿Qué comandos puedo usar?", answer: "Puedes usar comandos como 'Ver vuelos', 'Buscar clima', y 'Reservar asiento'." },
    { id: "3", question: "¿Cómo puedo cambiar el idioma del asistente?", answer: "Ve a la configuración del asistente y selecciona el idioma deseado." },
  ],
  emptyMessage = "No hay preguntas frecuentes disponibles.",
}: FAQProps) {
  const [expanded, setExpanded] = useState<string | null>(null);

  const toggleExpand = (id: string) => {
    setExpanded(expanded === id ? null : id);
  };

  return (
    <Card className="w-full max-w-md mx-auto flex flex-col content-card-background">
      <CardHeader className="flex-shrink-0 border-b flex flex-col items-center">
        <CardTitle className="flex items-center gap-2">
          {title}
        </CardTitle>
        <p className="text-sm text-muted-foreground text-center">{description}</p>
      </CardHeader>
      <div className="flex-1 overflow-auto">
        <CardContent className="p-4">
          {faqs.length === 0 ? (
            <div className="text-center text-muted-foreground py-4">
              {emptyMessage}
            </div>
          ) : (
            <div className="space-y-4">
              {faqs.map((faq) => (
                <div key={faq.id} className="border-b pb-2">
                  <Button
                    variant="ghost"
                    className="flex justify-between items-center text-left"
                    onClick={() => toggleExpand(faq.id)}
                  >
                    <span className="text-sm font-medium">{faq.question}</span>
                    {expanded === faq.id ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
                  </Button>
                  {expanded === faq.id && (
                    <div className="mt-2 text-sm text-muted-foreground overflow-hidden">
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </CardContent>
      </div>
    </Card>
  );
} 