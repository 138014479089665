export enum ToolName {
  SET_MEMORY = 'set_memory',
  WEATHER = 'get_weather',
  UPDATE_FLIGHT = 'update_flight',
  SEND_MESSAGE = 'send_message',
  SEND_MESSAGE_CODE = 'send_message_code',
  UPDATE_FLIGHT_CODE = 'update_flight_code',
  UPDATE_CHECKED_BAGS = 'update_checked_bags',
  SHOW_FLIGHT = 'show_flight',
  SHOW_CHECKED_BAGS = 'show_checked_bags',
  CREATE_TICKET = 'create_ticket',
  CHECK_TICKET_STATUS = 'check_ticket_status',
  ESCALATE_PROBLEM = 'escalate_problem',
  CHECK_POLICY_STATUS = 'check_policy_status',
}

export type ToolSet = ToolName;

export interface ToolDefinition {
  name: ToolName;
  description: string;
  parameters: {
    type: string;
    properties: {
      [key: string]: {
        type: string;
        description: string;
        enum?: string[];
      };
    };
    required: string[];
  };
  handler: (params: any) => Promise<any>;
}

export type ToolsConfig = {
  [K in ToolName]: ToolDefinition;
} 

export enum FlightUpdateKeyCode {
  DEPARTURE_CITY = 'departure_city',
  DEPARTURE_DATE = 'departure_date',
  DEPARTURE_CITY_CODE = 'departure_city_IATA_code',

  DESTINATION_CITY = 'destination_city',
  DESTINATION_CITY_CODE = 'destination_city_IATA_code',
  RETURN_DATE = 'return_date',

}

export enum FlightUpdateKey {
  ORIGIN_CITY = 'origin_city',
  DESTINATION_CITY = 'destination_city',
  DEPARTURE_DATE = 'departure_date',
  RETURN_DATE = 'return_date',
}

export enum CardName {
  FLIGHT_DATES = 'FLIGHT_DATES',
  FLIGHT_DATES_CODE = 'FLIGHT_DETAILS_CODE',
  BUTTONS_HEADER = 'BUTTONS_HEADER',
  BUTTONS_PLAIN = 'BUTTONS_PLAIN',
  METRICS_2COL = 'METRICS_2COL',
  TERMS_2BUTTONS = 'TERMS_2BUTTONS',
  CONVERSATION_HISTORY = 'CONVERSATION_HISTORY',
  QUICK_GUIDE = 'QUICK_GUIDE',
  INFORMATION_SUMMARY = 'INFORMATION_SUMMARY',
  FLIGHT_TRACKING = 'FLIGHT_TRACKING',
  FAQ = 'FAQ',
  CHECKED_BAGS = 'CHECKED_BAGS', // Asegúrate de que esta línea esté presente
  LOCATION_MAP = 'LOCATION_MAP',
  PERSONAL_ORGANIZER = 'PERSONAL_ORGANIZER'
}

export interface LocationMapData {
  title?: string;
  description?: string;
  defaultLocation?: [number, number];
  defaultZoom?: number;
  showCurrentLocation?: boolean;
  onLocationSelect?: (location: { lat: number; lng: number }) => void;
}

export interface Task {
  id: string;
  title: string;
  description: string;
  dueDate: Date;
  completed: boolean;
}

export interface PersonalOrganizerData {
  title?: string;
  description?: string;
  tasks?: Task[];
  onAddTask?: (task: Omit<Task, 'id'>) => void;
  onUpdateTask?: (task: Task) => void;
  onDeleteTask?: (taskId: string) => void;
  onToggleTask?: (taskId: string) => void;
}