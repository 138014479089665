import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/card"
import { Button } from "../../../components/ui/button"
import { Input } from "../../../components/ui/input"
import { Plane, Clock, AlertTriangle, CheckCircle2, Search, ArrowRight } from 'lucide-react'

interface FlightStatus {
  id: string;
  flightNumber: string;
  status: 'on-time' | 'delayed' | 'cancelled';
  departureTime: string;
  arrivalTime: string;
  origin: string;
  destination: string;
  gate?: string;
  terminal?: string;
  delayTime?: string;
}

interface FlightTrackingProps {
  title?: string;
  description?: string;
  placeholder?: string;
  searchButtonText?: string;
  emptyMessage?: string;
  flightStatus?: FlightStatus;
  onSearch?: (flightNumber: string) => void;
  showDetails?: boolean;
}

export default function FlightTracking({
  title = "Seguimiento de Vuelo",
  description = "Ingresa el número de vuelo para ver su estado",
  placeholder = "Ej: SA1234",
  searchButtonText = "Buscar",
  emptyMessage = "Ingresa un número de vuelo para ver su estado",
  flightStatus = {
    id: "1",
    flightNumber: "SA1234",
    status: "on-time",
    departureTime: "10:00 AM",
    arrivalTime: "12:00 PM",
    origin: "Madrid",
    destination: "Barcelona",
    gate: "A12",
    terminal: "T4",
  },
  onSearch = () => {},
  showDetails = true,
}: FlightTrackingProps) {
  const getStatusInfo = (status: string) => {
    switch (status) {
      case 'on-time':
        return {
          icon: <CheckCircle2 className="h-5 w-5 text-green-500" />,
          text: "En horario",
          bgColor: "bg-green-50",
          textColor: "text-green-700"
        };
      case 'delayed':
        return {
          icon: <Clock className="h-5 w-5 text-yellow-500" />,
          text: "Retrasado",
          bgColor: "bg-yellow-50",
          textColor: "text-yellow-700"
        };
      case 'cancelled':
        return {
          icon: <AlertTriangle className="h-5 w-5 text-red-500" />,
          text: "Cancelado",
          bgColor: "bg-red-50",
          textColor: "text-red-700"
        };
      default:
        return {
          icon: <Clock className="h-5 w-5 text-gray-500" />,
          text: "Desconocido",
          bgColor: "bg-gray-50",
          textColor: "text-gray-700"
        };
    }
  };

  return (
    <Card className="w-full mx-auto flex flex-col content-card-background">
      <CardHeader className="flex-shrink-0 border-b">
        <CardTitle className="flex items-center gap-2 justify-center">
          <Plane className="h-5 w-5" />
          {title}
        </CardTitle>
        <p className="text-sm text-muted-foreground">{description}</p>
      </CardHeader>
      <div className="flex-1 overflow-auto">
        <CardContent className="p-6">
          <div className="flex gap-2 mb-6">
            <Input
              placeholder={placeholder}
              className="flex-1"
              onChange={(e) => onSearch(e.target.value)}
            />
            <Button className="flex-shrink-0">
              <Search className="h-4 w-4 mr-2" />
              {searchButtonText}
            </Button>
          </div>

          {!flightStatus ? (
            <div className="text-center text-muted-foreground py-4">
              {emptyMessage}
            </div>
          ) : (
            <div className="space-y-6">
              <div className={`p-4 rounded-lg ${getStatusInfo(flightStatus.status).bgColor}`}>
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center gap-2">
                    <span className="text-lg font-semibold">{flightStatus.flightNumber}</span>
                    {getStatusInfo(flightStatus.status).icon}
                    <span className={`text-sm font-medium ${getStatusInfo(flightStatus.status).textColor}`}>
                      {getStatusInfo(flightStatus.status).text}
                    </span>
                  </div>
                  {flightStatus.status === 'delayed' && flightStatus.delayTime && (
                    <span className="text-sm text-yellow-600">
                      +{flightStatus.delayTime} min
                    </span>
                  )}
                </div>

                <div className="flex justify-between items-center">
                  <div className="text-center flex-1">
                    <p className="text-sm text-muted-foreground">Salida</p>
                    <p className="text-lg font-semibold">{flightStatus.departureTime}</p>
                    <p className="text-sm font-medium">{flightStatus.origin}</p>
                  </div>
                  <ArrowRight className="h-5 w-5 text-muted-foreground mx-4" />
                  <div className="text-center flex-1">
                    <p className="text-sm text-muted-foreground">Llegada</p>
                    <p className="text-lg font-semibold">{flightStatus.arrivalTime}</p>
                    <p className="text-sm font-medium">{flightStatus.destination}</p>
                  </div>
                </div>
              </div>

              {showDetails && (
                <div className="grid grid-cols-2 gap-4">
                  <div className="p-4 bg-muted/10 rounded-lg">
                    <p className="text-sm text-muted-foreground">Terminal</p>
                    <p className="text-lg font-semibold">{flightStatus.terminal || '-'}</p>
                  </div>
                  <div className="p-4 bg-muted/10 rounded-lg">
                    <p className="text-sm text-muted-foreground">Puerta</p>
                    <p className="text-lg font-semibold">{flightStatus.gate || '-'}</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </CardContent>
      </div>
    </Card>
  );
}