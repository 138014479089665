"use client"

import { useState } from "react"
import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/card"
import { Button } from "../../../components/ui/button"
import { Input } from "../../../components/ui/input"
import { Calendar, ListTodo, Plus, Trash2, CheckCircle2, Circle, Filter, Pencil } from 'lucide-react'
import { format } from 'date-fns'
import { Task } from "../../../types/tools"
import { Checkbox } from "@radix-ui/themes"

type FilterType = 'all' | 'pending' | 'completed';

interface PersonalOrganizerProps {
  title?: string;
  description?: string;
  tasks?: Task[];
  onAddTask?: (task: Omit<Task, 'id'>) => void;
  onUpdateTask?: (task: Task) => void;
  onDeleteTask?: (taskId: string) => void;
  onToggleTask?: (taskId: string) => void;
}

export default function PersonalOrganizer({
  title = "Organizador Personal",
  description = "Gestiona tus tareas y eventos diarios",
  tasks: initialTasks = [],
  onAddTask = () => {},
  onUpdateTask = () => {},
  onDeleteTask = () => {},
  onToggleTask = () => {},
}: PersonalOrganizerProps) {
  const [showModal, setShowModal] = useState(false)
  const [localTasks, setLocalTasks] = useState<Task[]>(initialTasks)
  const [filter, setFilter] = useState<FilterType>('all')
  const [newTask, setNewTask] = useState({
    title: '',
    description: '',
    dueDate: new Date(),
  })
  const [editingTask, setEditingTask] = useState<Task | null>(null)

  const stats = {
    total: localTasks.length,
    completed: localTasks.filter(task => task.completed).length,
    pending: localTasks.filter(task => !task.completed).length
  }

  const filteredTasks = localTasks.filter(task => {
    switch (filter) {
      case 'completed':
        return task.completed
      case 'pending':
        return !task.completed
      default:
        return true
    }
  })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    
    if (editingTask) {
      const updatedTask: Task = {
        ...editingTask,
        title: newTask.title,
        description: newTask.description,
        dueDate: newTask.dueDate
      }
      
      setLocalTasks(prev => 
        prev.map(task => task.id === editingTask.id ? updatedTask : task)
      )
      onUpdateTask(updatedTask)
    } else {
      const task: Omit<Task, 'id'> = {
        ...newTask,
        completed: false,
      }
      
      const newTaskWithId: Task = {
        ...task,
        id: crypto.randomUUID()
      }

      setLocalTasks(prev => [...prev, newTaskWithId])
      onAddTask(task)
    }
    
    setShowModal(false)
    setEditingTask(null)
    setNewTask({
      title: '',
      description: '',
      dueDate: new Date(),
    })
  }

  const handleToggleTask = (taskId: string) => {
    setLocalTasks(prev =>
      prev.map(task =>
        task.id === taskId ? { ...task, completed: !task.completed } : task
      )
    )
    onToggleTask(taskId)
  }

  const handleDeleteTask = (taskId: string) => {
    setLocalTasks(prev => prev.filter(task => task.id !== taskId))
    onDeleteTask(taskId)
  }

  const handleEditTask = (task: Task) => {
    setEditingTask(task)
    setNewTask({
      title: task.title,
      description: task.description,
      dueDate: new Date(task.dueDate)
    })
    setShowModal(true)
  }

  return (
    <Card className={`w-full mx-auto content-card-background transition-all duration-300 ${
      showModal ? 'h-[500px]' : ''
    }`}>
      <CardHeader className="flex-shrink-0 border-b">
        <CardTitle className="flex items-center gap-2 justify-center">
          <ListTodo className="h-5 w-5" />
          {editingTask ? 'Editar Tarea' : 'Nueva Tarea'}
        </CardTitle>
        <p className="text-sm text-muted-foreground text-center">
          {showModal ? 'Completa los detalles de la tarea' : description}
        </p>
        {!showModal && (
          <div className="flex justify-between items-center mt-2 text-sm">
            {/* <div className="flex gap-4">
              <span>Total: {stats.total}</span>
              <span className="text-green-600">Completadas: {stats.completed}</span>
              <span className="text-yellow-600">Pendientes: {stats.pending}</span>
            </div> */}
            <div className="flex gap-2">
              <Button
                variant={filter === 'all' ? 'default' : 'outline'}
                size="sm"
                onClick={() => setFilter('all')}
                className="text-xs"
              >
                Todas
              </Button>
              <Button
                variant={filter === 'pending' ? 'default' : 'outline'}
                size="sm"
                onClick={() => setFilter('pending')}
                className="text-xs"
              >
                Pendientes
              </Button>
              <Button
                variant={filter === 'completed' ? 'default' : 'outline'}
                size="sm"
                onClick={() => setFilter('completed')}
                className="text-xs"
              >
                Completadas
              </Button>
            </div>
          </div>
        )}
      </CardHeader>
      <CardContent className="p-4 h-[calc(100%-6rem)] overflow-hidden">
        {!showModal ? (
          <div className="space-y-4">
            <Button
              onClick={() => setShowModal(true)}
              className="w-full flex items-center gap-2 justify-center"
            >
              <Plus className="h-4 w-4" />
              Agregar Tarea
            </Button>

            <div className="space-y-2 max-h-[300px] overflow-y-auto">
              {filteredTasks.length === 0 ? (
                <p className="text-center text-sm text-muted-foreground py-4">
                  {filter === 'all' 
                    ? 'No hay tareas' 
                    : filter === 'completed' 
                      ? 'No hay tareas completadas' 
                      : 'No hay tareas pendientes'}
                </p>
              ) : (
                filteredTasks.map((task) => (
                  <div
                    key={task.id}
                    className={`p-3 rounded-lg border transition-colors group ${
                      task.completed ? 'bg-muted/50 border-green-200' : 'bg-card hover:border-yellow-200'
                    }`}
                  >
                    <div className="flex items-start gap-3">
                      <button
                        onClick={() => handleToggleTask(task.id)}
                        className={`mt-1 transition-colors ${
                          task.completed ? 'text-green-500' : 'text-muted-foreground hover:text-yellow-500'
                        }`}
                      >
                        {task.completed ? (
                          <CheckCircle2 className="h-5 w-5" />
                        ) : (
                          <Circle className="h-5 w-5" />
                        )}
                      </button>
                      <div className="flex-1 min-w-0">
                        <h3 className={`font-medium transition-all ${
                          task.completed ? 'line-through text-muted-foreground' : ''
                        }`}>
                          {task.title}
                        </h3>
                        <p className="text-sm text-muted-foreground">
                          {task.description}
                        </p>
                        <div className="flex items-center gap-2 mt-2 text-xs text-muted-foreground">
                          <Calendar className="h-3 w-3" />
                          <span>
                            {format(new Date(task.dueDate), 'dd MMM yyyy HH:mm')}
                          </span>
                        </div>
                      </div>
                      <div className="flex gap-1">
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleEditTask(task)}
                          className="h-8 w-8 bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 transition-colors"
                        >
                          <Pencil className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleDeleteTask(task.id)}
                          className="h-8 w-8 bg-red-50 text-red-600 hover:bg-red-100 hover:text-red-700 transition-colors"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        ) : (
          <div className="h-full flex flex-col">
            <form onSubmit={handleSubmit} className="flex-1 flex flex-col space-y-4">
              <div className="space-y-2">
                <label className="text-sm font-medium">Título</label>
                <Input
                  value={newTask.title}
                  onChange={(e) => setNewTask({
                    ...newTask,
                    title: e.target.value
                  })}
                  placeholder="Título de la tarea"
                  required
                />
              </div>
              <div className="flex-1 space-y-2">
                <label className="text-sm font-medium">Descripción</label>
                <textarea
                  value={newTask.description}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setNewTask({
                    ...newTask,
                    description: e.target.value
                  })}
                  placeholder="Descripción de la tarea"
                  className="w-full h-[calc(100%-2rem)] min-h-[100px] p-2 rounded-md border resize-none focus:outline-none focus:ring-2 focus:ring-primary"
                />
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium">Fecha límite</label>
                <div className="flex gap-2">
                  <Input
                    type="datetime-local"
                    value={format(newTask.dueDate, "yyyy-MM-dd'T'HH:mm")}
                    onChange={(e) => setNewTask({
                      ...newTask,
                      dueDate: new Date(e.target.value)
                    })}
                    required
                  />
                </div>
              </div>
              <div className="flex justify-end gap-2 pt-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setShowModal(false)}
                >
                  Cancelar
                </Button>
                <Button type="submit">
                  {editingTask ? 'Guardar Cambios' : 'Agregar'}
                </Button>
              </div>
            </form>
          </div>
        )}
      </CardContent>
    </Card>
  )
} 