import { VirtualAgent } from '../data/virtualAgents';
import { agentsData } from '../data/agentsData';
import * as LucideIcons from "lucide-react";
import { ToolName } from '../types/tools';
import { BackgroundType } from "../types/backgrounds";

// Determine the API base URL based on the environment
const API_BASE_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_BASE_URL_DEV
  : process.env.REACT_APP_API_BASE_URL;

// console.log("🌐 Ambiente:", process.env.NODE_ENV);
// console.log("🔗 URL base de API (DEV):", process.env.REACT_APP_API_BASE_URL_DEV);
// console.log("🔗 URL base de API (PROD):", process.env.REACT_APP_API_BASE_URL);
// console.log("🔗 URL base de API (FINAL):", API_BASE_URL);

// Function to fetch agent names by teamId
export const fetchAgentNames = async (teamId: string): Promise<VirtualAgent[]> => {
  try {
    const fullUrl = `${API_BASE_URL}/api/agent-directory/team/${teamId}`; // Use teamId parameter
    const response = await fetch(fullUrl, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    
    if (!response.ok) {
      throw new Error(`Failed to fetch agent names: ${response.status} ${response.statusText}`);
    }
    
    const rawAgents = await response.json();
    const transformedAgents = rawAgents.map(transformApiAgent);
    return transformedAgents;
  } catch (error) {
    return agentsData as VirtualAgent[];
  }
};

// Function to fetch a single agent by botId
export const fetchAgentById = async (botId: string): Promise<VirtualAgent | null> => {
  try {
    const fullUrl = `${API_BASE_URL}/api/agent-directory/${botId}`; // Fetch by botId
    const response = await fetch(fullUrl, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch agent by ID: ${response.status} ${response.statusText}`);
    }

    const rawAgent = await response.json();
    return transformApiAgent(rawAgent);
  } catch (error) {
    console.error('Error fetching agent by ID:', error);
    return null;
  }
};

// Example usage of the function
//(async () => {
//  try {
//    const agentNames = await fetchAgentNames();
//    console.log(agentNames);
//  } catch (error) {
//    console.error('Error in example usage:', error);
//  }
//})();

// Función para transformar los datos de la API
const transformApiAgent = (apiAgent: any): VirtualAgent => {
  // Asegurarnos que tools sea siempre un array
  const toolsArray = Array.isArray(apiAgent.tools) ? apiAgent.tools : [];
  
  return {
    ...apiAgent,
    // Convertir string de icon al componente real
    icon: LucideIcons[apiAgent.icon as keyof typeof LucideIcons] || LucideIcons.Bot, // Bot como fallback

    // Convertir strings de tools a enum con validación
    tools: toolsArray.map((tool: string) => {
      if (typeof tool === 'string' && tool in ToolName) {
        return ToolName[tool as keyof typeof ToolName];
      }
      return tool;
    }),

    // Convertir string de background a enum con validación
    background: (apiAgent.background && apiAgent.background in BackgroundType)
      ? BackgroundType[apiAgent.background as keyof typeof BackgroundType]
      : BackgroundType.NATURAL_ORGANIC,

    // Asegurar booleanos con valores por defecto
    showCardButtons: Boolean(apiAgent.showCardButtons ?? true),
    visibleInShowcase: Boolean(apiAgent.visibleInShowcase ?? true),
  };
};
