import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card"

interface MetricsData {
  title: string;
  value: string;
}

interface Metrics2ColProps {
  cardTitle?: string;
  metrics?: MetricsData[];
}

export default function Metrics2Col({ 
  cardTitle = "Company Metrics",
  metrics = [
    { title: "Total Revenue", value: "$45,231.89" },
    { title: "Subscriptions", value: "2,234" },
    { title: "Sales", value: "1,234" },
    { title: "Active Users", value: "10,234" },
  ]
}: Metrics2ColProps) {
  return (
    <Card className="w-full mx-auto content-card-background">
      <CardHeader>
        <CardTitle>{cardTitle}</CardTitle>
      </CardHeader>
      <CardContent className="grid grid-cols-2 gap-4">
        {metrics.map((item, index) => (
          <div key={index} className="flex flex-col space-y-1">
            <p className="text-sm font-medium text-muted-foreground">{item.title}</p>
            <p className="text-2xl font-bold">{item.value}</p>
          </div>
        ))}
      </CardContent>
    </Card>
  )
}