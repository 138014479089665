/**
 * Running a local relay server will allow you to hide your API key
 * and run custom logic on the server
 *
 * Set the local relay server address to:
 * REACT_APP_LOCAL_RELAY_SERVER_URL=http://localhost:8081
 *
 * This will also require you to set OPENAI_API_KEY= in a `.env` file
 * You can run it with `npm run relay`, in parallel with `npm start`
 */

import { useEffect, useRef, useCallback, useState } from 'react';

import { RealtimeClient } from '@openai/realtime-api-beta';
import type { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { WavRenderer } from '../utils/wav_renderer';

import { X, Edit, Zap, ArrowUp, ArrowDown, Mic, MicOff } from 'react-feather';
import { Button } from '../components/button/Button';
import { Toggle } from '../components/toggle/Toggle';
import { MessagesChat } from '../components/chat/MessagesChat';

import './syntphony-playht.scss';
import { isJsxOpeningLikeElement } from 'typescript';
import Background from '../components/Background/Background';
import LogoSyntphony from '../components/Logo/Logo.js';
import { VirtualAgent } from '../data/virtualAgents';
import { AgentCustomUI } from '../types/agent-ui';
import { createToolsConfig } from '../data/tools/toolSets';
import { ToolsConfig, ToolSet } from '../types/tools';
import { Coordinates } from '@/types.js';
import { FloatingMenu } from '../components/FloatingMenu/FloatingMenu';
import { CardName } from '../types/tools';
import { sendMessageHandler } from '../utils/messageUtils';
import { Header } from '../components/Header/Header';
import { BackgroundType } from '../types/backgrounds';

/**
 * Type for all event logs
 */
interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

interface SyntphonyPlayHTProps {
  config?: VirtualAgent;
  customUI?: AgentCustomUI & {
    customLogo?: React.ReactNode;
    title?: string;
    subtitle?: string;
  };
}

const LOCAL_RELAY_SERVER_URL: string = 
  process.env.REACT_APP_RELAY_URL || '';

export function SyntphonyPlayHT({ config, customUI }: SyntphonyPlayHTProps) {
  const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

  useEffect(() => {
    if (LOCAL_RELAY_SERVER_URL) {
      console.log('Using relay server at:', LOCAL_RELAY_SERVER_URL);
    } else {
      console.log('Using direct API key connection');
    }
  }, []);

  useEffect(() => {
    if (config) {
      console.log('Agent Configuration:', config);
    }
  }, [config]);

  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient(
      LOCAL_RELAY_SERVER_URL
        ? { url: LOCAL_RELAY_SERVER_URL }
        : {
            apiKey: apiKey,
            dangerouslyAllowAPIKeyInBrowser: true,
          }
    )
  );

  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());

  const [items, setItems] = useState<ItemType[]>([]);
  const [expandedEvents, setExpandedEvents] = useState<{
    [key: string]: boolean;
  }>({});
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const hasConnectedOnce = useRef(false);
  const [memoryKv, setMemoryKv] = useState<{ [key: string]: any }>({});
  const [coords, setCoords] = useState<Coordinates | null>(null);
  const [marker, setMarker] = useState<Coordinates | null>(null);
  const [cardData, setCardData] = useState<{ [key: string]: any }>({});

  const showDataInCards = (cardName: CardName, data: { [key: string]: any }) => {
    console.log(`showDataInCards called with cardName: ${cardName}, data:`, data);
    
    // Limpiar datos anteriores y establecer solo los nuevos
    setCardData((prevData) => {
      const newData = {
        [cardName]: data
      };
      
      // Opcional: mantener datos de otras cards por un tiempo limitado
      setTimeout(() => {
        setCardData(currentData => {
          if (currentData[cardName] === data) {
            return { [cardName]: data };
          }
          return currentData;
        });
      }, 100); // pequeño delay para asegurar la transición suave

      return newData;
    });
  };
  //mostra showdata in cards.
  //ocultar hideallcards.
  //llamar servicio externo.
  //llamar y agendar reloj para consultar X segundos despues.
  

  const wavRecorder = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayer = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );


  const connectConversation = useCallback(async () => {
    if (isConnected || isConnecting || hasConnectedOnce.current) return;
    setIsConnecting(true);
    hasConnectedOnce.current = true;

    try {
      console.log(LOCAL_RELAY_SERVER_URL 
        ? 'Connecting via relay server...'
        : 'Connecting directly to OpenAI API...'
      );
      
      const client = clientRef.current;

      // Set state variables
      startTimeRef.current = new Date().toISOString();
      setItems(client.conversation.getItems());

      // Connect to microphone
      if (wavRecorder.current.getStatus() !== 'recording') {
        console.log('Connecting to microphone...');
        await wavRecorder.current.begin();
        console.log('Microphone connected.');
      }

      // Connect to audio output
      console.log('Connecting to audio output...');
      await wavStreamPlayer.current.connect();
      console.log('Audio output connected.');

      // Connect to API (either directly or via relay)
      // Connect to realtime API
      await client.connect();
      console.log('Connected to OpenAI API successfully.');

      // Wait for the session to be fully created
      await client.waitForSessionCreated();
      console.log('Session created.');

      // Ensure VAD mode is active
      client.updateSession({ turn_detection: { type: 'server_vad' } });
      if (wavRecorder.current.getStatus() !== 'recording') {
        await wavRecorder.current.record((data) => client.appendInputAudio(data.mono));
      }

      client.sendUserMessageContent([
        {
          type: `input_text`,
          text: ` `,
        },
      ]);

      setIsConnected(true);
    } catch (error) {
      console.error('Failed to connect to OpenAI API:', error);
    } finally {
      setIsConnecting(false);
    }
  }, [isConnected, isConnecting]);

  const disconnectConversation = useCallback(async () => {
    try {
      const client = clientRef.current;
      const wavRecorderInstance = wavRecorder.current;
      const wavStreamPlayerInstance = wavStreamPlayer.current;

      // Check if the client is connected before attempting to disconnect
      if (client?.isConnected()) {
        // Stop recording first if active
        if (wavRecorderInstance?.getStatus() === 'recording') {
          try {
            await wavRecorderInstance.pause();
          } catch (e) {
            console.warn('Error pausing recorder:', e);
          }
        }

        // Disconnect from the API
        try {
          await client.disconnect();
        } catch (e) {
          console.warn('Error disconnecting client:', e);
        }
      } else {
        console.warn('Client is not connected, skipping disconnect.');
      }

      // Stop any ongoing audio playback
      if (wavStreamPlayerInstance) {
        try {
          await wavStreamPlayerInstance.interrupt();
          await wavStreamPlayerInstance.destroy();
        } catch (e) {
          console.warn('Error destroying stream player:', e);
        }
      }

      // Reset states
      setIsConnected(false);
      setIsRecording(false);
      hasConnectedOnce.current = false;
    } catch (error) {
      console.warn('Cleanup warning:', error);
    }
  }, []);

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);

  const startRecording = async () => {
    setIsRecording(true);
    const client = clientRef.current;
    const wavRecorderInstance = wavRecorder.current;
    const wavStreamPlayerInstance = wavStreamPlayer.current;
    const trackSampleOffset = await wavStreamPlayerInstance.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorderInstance.record((data) => client.appendInputAudio(data.mono));
  };

  const stopRecording = async () => {
    setIsRecording(false);
    const client = clientRef.current;
    const wavRecorderInstance = wavRecorder.current;
    await wavRecorderInstance.pause();
    client.createResponse();
  };

  const toggleMute = useCallback(() => {
    const client = clientRef.current;
    const wavRecorderInstance = wavRecorder.current;

    if (isMuted) {
      client.updateSession({ turn_detection: { type: 'server_vad' } });
      if (client.isConnected()) {
        wavRecorderInstance.record((data) => client.appendInputAudio(data.mono));
      }
    } else {
      client.updateSession({ turn_detection: null });
      if (wavRecorderInstance.getStatus() === 'recording') {
        wavRecorderInstance.pause();
      }
    }

    setIsMuted(!isMuted);
  }, [isMuted]);

  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  useEffect(() => {
    let isLoaded = true;

    const wavRecorderInstance = wavRecorder.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayerInstance = wavStreamPlayer.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorderInstance.recording
              ? wavRecorderInstance.getFrequencies('voice')
              : { values: new Float32Array([0]) };

            const clientColor1 = getComputedStyle(document.documentElement)
              .getPropertyValue('--client-bar-color1')
              .trim();
            const clientColor2 = getComputedStyle(document.documentElement)
              .getPropertyValue('--client-bar-color2')
              .trim();

            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              clientColor1,
              clientColor2,
              40,
              0,
              0,
              'right',
              0.8,
              0.2
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayerInstance.analyser
              ? wavStreamPlayerInstance.getFrequencies('voice')
              : { values: new Float32Array([0]) };

            const serverColor1 = getComputedStyle(document.documentElement)
              .getPropertyValue('--server-bar-color1')
              .trim();
            const serverColor2 = getComputedStyle(document.documentElement)
              .getPropertyValue('--server-bar-color2')
              .trim();

            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              serverColor1,
              serverColor2,
              40,
              0,
              0,
              'left',
              0.8,
              0.2
            );
          }
        }
        requestAnimationFrame(render);
      }
    };

    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  useEffect(() => {
    const client = clientRef.current;
    const wavStreamPlayerInstance = wavStreamPlayer.current;

    client.updateSession({ instructions: config?.instructions });
    client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });
    client.updateSession({ voice: 'alloy' });

    if (config?.tools) {
      const toolsConfig = createToolsConfig(
        setMemoryKv,
        setCoords,
        setMarker,
        showDataInCards,
        sendMessageHandler
      );

      config.tools.forEach((toolName) => {
        const tool = toolsConfig[toolName as keyof ToolsConfig];
        if (tool) {
          client.addTool(
            {
              name: tool.name,
              description: tool.description,
              parameters: tool.parameters,
            },
            async (params: Parameters<typeof tool.handler>[0]) => {
              console.log(` Tool ${toolName} called with args:`, params);
              const result = await tool.handler(params);
              console.log(`Tool ${toolName} result:`, result);
              return result;
            }
          );
        }
      });
    }

    client.on('error', (event: any) => console.error(event));
    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayerInstance.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on('conversation.updated', async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayerInstance.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      setItems(items);
    });

    setItems(client.conversation.getItems());

    return () => {
      client.reset();
    };
  }, [config]);

  useEffect(() => {
    let mounted = true;
    console.log('Component mounted');

    // Add navigation event handler
    const handleBeforeUnload = async () => {
      if (wavRecorder.current?.getStatus() === 'recording') {
        try {
          await wavRecorder.current.end();  // Explicitly end recording
        } catch (e) {
          console.warn('Error ending recorder:', e);
        }
      }
    };

    // Add event listener for navigation
    window.addEventListener('beforeunload', handleBeforeUnload);

    const connectOnLoad = async () => {
      if (!mounted) return;
      
      console.log('Waiting for everything to load...');
      await new Promise((resolve) => setTimeout(resolve, 2000));
      
      if (!mounted) return;
      
      console.log('Attempting to connect...');
      try {
        await connectConversation();
      } catch (error) {
        console.error('Connection error:', error);
      }
    };

    connectOnLoad();

    return () => {
      mounted = false;
      console.log('Component unmounting - cleaning up connections');
      
      // Remove navigation event listener
      window.removeEventListener('beforeunload', handleBeforeUnload);
      
      // Ensure WavRecorder is ended before cleanup
      if (wavRecorder.current?.getStatus() === 'recording') {
        wavRecorder.current.end()
          .then(() => disconnectConversation())
          .catch(error => console.warn('Final cleanup warning:', error));
      } else {
        disconnectConversation()
          .catch(error => console.warn('Final cleanup warning:', error));
      }
    };
  }, []);

  useEffect(() => {
    console.log('Environment Variables:', {
      NODE_ENV: process.env.NODE_ENV,
      REACT_APP_RELAY_URL: process.env.REACT_APP_RELAY_URL,
      //LOCAL_RELAY_SERVER_URL,
    });
    //console.log('Current Relay URL being used:', LOCAL_RELAY_SERVER_URL);
  }, []);

  return (
    <div className="syntphony-container">
      <Header 
        customLogo={customUI?.customLogo}
        title={customUI?.title}
        subtitle={customUI?.subtitle}
      />
      <div data-component="SyntphonyPlayHT" className="container-playht">
        <div>
          <Background 
            overlayOpacity={0.0} 
            type={config?.background as BackgroundType}
          />
          <div className="audio-visualization">
            <div className="audio-canvas-container server">
              <canvas ref={serverCanvasRef} className="audio-canvas" />
            </div>
            <div className="audio-canvas-container client">
              <canvas ref={clientCanvasRef} className="audio-canvas" />
            </div>
          </div>
          <div className="floating-menu-container">
            {config?.showCardButtons && <FloatingMenu cardData={cardData} />}
          </div>
          <div className="content-main">
            <div className="bottom-controls">
              <Button
                icon={isMuted ? MicOff : Mic}
                buttonStyle={isMuted ? 'alert' : 'regular'}
                onClick={toggleMute}
              />
            </div>
            <div className="content-block-body" data-conversation-content>
              <MessagesChat
                items={items}
                deleteConversationItem={deleteConversationItem}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="powered-by">
        Powered by Syntphony
      </div>
    </div>
  );
}
