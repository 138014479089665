import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/card"
import { Button } from "../../../components/ui/button"
import { Command, Plane, Cloud, Tag, Calendar, MapPin, CreditCard, Users, Search } from 'lucide-react'

interface Command {
  id: string;
  text: string;
  description?: string;
  icon?: JSX.Element;
  category?: string;
}

interface QuickGuideProps {
  title?: string;
  description?: string;
  commands?: Command[];
  emptyMessage?: string;
  categorized?: boolean;
  onCommandSelect?: (command: Command) => void;
  showIcons?: boolean;
}

export default function QuickGuide({
  title = "Guía Rápida",
  description = "Comandos disponibles para el asistente de voz",
  commands = [
    // Categoría Vuelos
    { id: "1", text: "Ver vuelos", description: "Muestra los vuelos disponibles", category: "Vuelos", icon: <Plane className="h-4 w-4" /> },
    { id: "2", text: "Reservar asiento", description: "Selecciona tu asiento preferido", category: "Vuelos", icon: <Users className="h-4 w-4" /> },
    { id: "3", text: "Cambiar vuelo", description: "Modifica tu reserva actual", category: "Vuelos", icon: <Calendar className="h-4 w-4" /> },
    
    // Categoría Clima
    { id: "4", text: "Buscar clima", description: "Consulta el pronóstico del tiempo", category: "Clima", icon: <Cloud className="h-4 w-4" /> },
    { id: "5", text: "Ver temperatura", description: "Temperatura actual del destino", category: "Clima", icon: <Search className="h-4 w-4" /> },
    { id: "6", text: "Alertas clima", description: "Revisa alertas meteorológicas", category: "Clima", icon: <MapPin className="h-4 w-4" /> },
    
    // Categoría Promociones
    { id: "7", text: "Ver ofertas", description: "Descubre las mejores ofertas", category: "Promociones", icon: <Tag className="h-4 w-4" /> },
    { id: "8", text: "Descuentos", description: "Códigos de descuento disponibles", category: "Promociones", icon: <CreditCard className="h-4 w-4" /> },
    { id: "9", text: "Paquetes", description: "Ofertas de vuelo + hotel", category: "Promociones", icon: <Search className="h-4 w-4" /> },
  ],
  emptyMessage = "No hay comandos disponibles",
  categorized = true,
  onCommandSelect = () => {},
  showIcons = true,
}: QuickGuideProps) {
  const groupedCommands = categorized
    ? commands.reduce((acc, command) => {
        const category = command.category || 'Otros';
        return {
          ...acc,
          [category]: [...(acc[category] || []), command],
        };
      }, {} as { [key: string]: Command[] })
    : { 'Todos': commands };

  return (
    <Card className="w-full mx-auto flex flex-col content-card-background">
      <CardHeader className="flex-shrink-0 border-b py-3">
        <CardTitle className="flex items-center gap-2 text-lg justify-center">
          {showIcons && <Command className="h-4 w-4" />}
          {title}
        </CardTitle>
        <p className="text-xs text-muted-foreground text-center">
          {description}
        </p>
      </CardHeader>
      <div className="flex-1 overflow-auto">
        <CardContent className="p-4">
          {commands.length === 0 ? (
            <div className="text-center text-muted-foreground py-4">
              {emptyMessage}
            </div>
          ) : (
            <div className="space-y-4">
              {Object.entries(groupedCommands).map(([category, categoryCommands]) => (
                <div key={category} className="space-y-2">
                  {categorized && (
                    <h3 className="text-sm font-medium text-muted-foreground text-center">
                      {category}
                    </h3>
                  )}
                  <div className="flex flex-wrap justify-center gap-2">
                    {categoryCommands.map((command) => (
                      <Button
                        key={command.id}
                        variant="outline"
                        size="sm"
                        className="group relative flex items-center gap-2 min-w-[120px] justify-center"
                        onClick={() => onCommandSelect(command)}
                      >
                        {showIcons && command.icon}
                        <span className="text-center">{command.text}</span>
                        {command.description && (
                          <div className="absolute invisible group-hover:visible w-48 bg-popover text-popover-foreground text-xs p-2 rounded-md -top-8 left-1/2 transform -translate-x-1/2 shadow-lg text-center">
                            {command.description}
                          </div>
                        )}
                      </Button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </CardContent>
      </div>
    </Card>
  );
} 