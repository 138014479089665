import { Card, CardContent, CardHeader } from "../../../components/ui/card"
import { Button } from "../../../components/ui/button"
import { ArrowRight, Home, Settings, User } from 'lucide-react'

interface ButtonHeaderProps {
  headerImage?: string;
  title?: string;
  description?: string;
  buttons?: {
    icon: typeof Home | typeof Settings | typeof User;
    title: string;
    description: string;
    link: string;
  }[];
}

export default function ButtonHeader({ 
  headerImage = "/placeholder.svg?height=200&width=400&text=Header+Image",
  title = "Navigation Options",
  description = "Select an option to navigate to the corresponding page",
  buttons = [
    { icon: Home, title: "Home", description: "Go to homepage", link: "/" },
    { icon: User, title: "Profile", description: "View your profile", link: "/profile" },
    { icon: Settings, title: "Settings", description: "Adjust your preferences", link: "/settings" },
  ]
}: ButtonHeaderProps) {
  return (
    <Card className="w-full mx-auto overflow-hidden content-card-background">
      <CardHeader className="p-0">
        <img
          src={headerImage}
          alt="Header image"
          className="w-full object-cover"
        />
      </CardHeader>
      <CardContent className="p-6 space-y-4">
        <div>
          <h2 className="text-xl font-semibold mb-2">{title}</h2>
          <p className="text-sm text-muted-foreground mb-4">{description}</p>
        </div>
        {buttons.map((button, index) => (
          <Button
            key={index}
            variant="outline"
            className="w-full justify-start text-left h-auto py-3"
          >
            <div className="flex items-center space-x-4">
              <button.icon className="h-5 w-5 text-muted-foreground" />
              <div className="flex-grow">
                <h3 className="font-medium">{button.title}</h3>
                <p className="text-sm text-muted-foreground">{button.description}</p>
              </div>
              <ArrowRight className="h-5 w-5 text-muted-foreground" />
            </div>
          </Button>
        ))}
      </CardContent>
    </Card>
  )
}