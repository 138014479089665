'use client'

import { useEffect, useState } from 'react'

const AnimatedBackground: React.FC = () => {
  const [particles, setParticles] = useState<Array<{ id: number; left: number; top: number; size: number; duration: number }>>([])

  useEffect(() => {
    // Aumentamos a 100 partículas
    const newParticles = Array.from({ length: 150 }, (_, i) => ({
      id: i,
      left: Math.random() * 100,
      top: Math.random() * 100,
      size: Math.random() * 3 + 1,
      duration: Math.random() * 20 + 10
    }))
    setParticles(newParticles)
  }, [])

  return (
    <div className="relative w-full h-screen overflow-hidden">
      {/* Background Image */}
      <div 
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url('https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-V7dNtQ1CdLP7iUokUI7uYxcG3kdAnX.png')`,
        }}
      />
      
      {/* Particles */}
      {particles.map((particle) => (
        <div
          key={particle.id}
          className="absolute rounded-full bg-white/30 animate-float"
          style={{
            left: `${particle.left}%`,
            top: `${particle.top}%`,
            width: `${particle.size}px`,
            height: `${particle.size}px`,
            animation: `float ${particle.duration}s infinite linear`,
            transform: 'translateZ(0)',
          }}
        />
      ))}

      <style >{`
        @keyframes float {
          0% {
            transform: translate(0, 0);
          }
          25% {
            transform: translate(100px, -100px);
          }
          50% {
            transform: translate(200px, 0);
          }
          75% {
            transform: translate(100px, 100px);
          }
          100% {
            transform: translate(0, 0);
          }
        }
        .animate-float {
          will-change: transform;
          backdrop-filter: blur(1px);
        }
      `}</style>
    </div>
  )
}

export default AnimatedBackground;
