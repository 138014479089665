import React, { useEffect, useState } from 'react';
import LogoSyntphony from '../Logo/Logo';
import './Header.scss';
import { useParams } from 'react-router-dom';
import { fetchAgentById } from '../../services/agentDirectoryService';

interface HeaderProps {
  customLogo?: React.ReactNode | string;
  title?: string;
  subtitle?: string;
}

export const Header: React.FC<HeaderProps> = ({ 
  customLogo = "",
  title = "Syntphony",
  subtitle = "Asistente Virtual"
}) => {
  const { agentId } = useParams();
  const [currentAgent, setCurrentAgent] = useState<any>(null);

  useEffect(() => {
    const fetchAgentData = async () => {
      if (agentId) {
        const agent = await fetchAgentById(agentId);
        setCurrentAgent(agent);
      }
    };

    fetchAgentData();
  }, [agentId]);

  const displayTitle = currentAgent?.name || title;
  const displaySubtitle = currentAgent?.description || subtitle;
  const displayLogo = currentAgent?.logo || customLogo;
  const IconComponent = currentAgent?.icon;

  const renderLogo = () => {
    if (!displayLogo && IconComponent) {
      return <IconComponent className="w-5 h-5" style={{ color: currentAgent?.color }} />;
    }

    if (typeof displayLogo === 'string') {
      return <img src={displayLogo} alt="Logo personalizado" className="custom-logo" />;
    }

    return displayLogo || <LogoSyntphony />;
  };

  return (
    <header className="app-header">
      <div className="header-content">
        <div className="logo-container">
          {renderLogo()}
        </div>
        <div className="title-container">
          <h1 className="title">{displayTitle}</h1>
          <p className="subtitle">{displaySubtitle}</p>
        </div>
      </div>
    </header>
  );
}; 