import React from 'react';
import './Background.css';
import AnimatedBackground from '../AnimatedBackground';
import NaturalOrganicGradientBackground from '../natural-organic-gradient-background';
import { BackgroundType } from '../../types/backgrounds';

interface BackgroundProps {
  overlayOpacity?: number;
  type?: BackgroundType;
}

const getBackgroundComponent = (type: BackgroundType) => {
  switch (type) {
    case BackgroundType.NATURAL_ORGANIC:
      return <NaturalOrganicGradientBackground />;
    case BackgroundType.ANIMATED_MOUNTAINS:
    default:
      return <AnimatedBackground />;
  }
};

const Background: React.FC<BackgroundProps> = ({ 
  overlayOpacity = 0,
  type = BackgroundType.ANIMATED_MOUNTAINS
}) => {
  return (
    <div id="player" className="player player-normal app-md player-md with-sticky-custom-logo hide-controls-mode">
      <div className="vp-video-wrapper transparent">
        {getBackgroundComponent(type)}
      </div>
      <div 
        className="video-overlay"
        style={{ backgroundColor: `rgba(255, 255, 255, ${overlayOpacity})` }} 
      />
    </div>
  );
};

export default Background; 