// src/utils/messageUtils.ts

import axios from 'axios';

const getWeekday = (dateString: string, language: string) => {
  const date = new Date(dateString);
  const weekdays = {
    ja: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
    en: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  };
  return weekdays[language as keyof typeof weekdays][date.getUTCDay()];
};

export const sendMessageHandler = (
  fullName: string,
  origin_city: string,
  destination_city: string,
  departure_date: string,
  return_date: string,
  phonenumber: string,
  language: string
) => {
  
  const templateConfig = {
    name: "booking_demo", 
    languageCode: language === 'ja' ? "ja" : "en"
  };
  const departureWeekday = getWeekday(departure_date, language);
  const returnWeekday = getWeekday(return_date, language);

  const payload = {
    messaging_product: "whatsapp",
    recipient_type: "individual",
    to: phonenumber,
    type: "template",
    template: {
      name: templateConfig.name,
      language: {
        code: templateConfig.languageCode,
      },
      components: [
        {
          type: "header",
          parameters: [
            {
              type: "document",
              document: {
                link: "http://syntphony.ai/pdf/template.pdf",
                filename: "boarding-pass.pdf",
              },
            },
          ],
        },
        {
          type: "body",
          parameters: [
            { type: "text", text: fullName },
            { type: "text", text: origin_city },
            { type: "text", text: destination_city },
            { type: "text", text: departure_date },
            { type: "text", text: departureWeekday },
            { type: "text", text: return_date },
            { type: "text", text: returnWeekday },
          ],
        },
      ],
    },
  };
  
  console.log("Payload:", JSON.stringify(payload, null, 2));

  axios.post('https://graph.facebook.com/v21.0/453058677887705/messages', payload, {
    headers: {
      'Authorization': `Bearer EAAOHUhb2OdcBOZCVFSl7hmoZCr6GMWfBUVAQWrQZCNZApZC8wGULq14Hs6D7w6dd047BtZCNjnami06AA9ZAXIDMwdDRrPwaBZCdKbsblynZCPT9AljMlFsT0r7Bco270DNZBsFkZC5aRf9K5fOZAHTg2uTn3UV7KSG2ZC8gmZAqLuTWtdNQjZBAnphEokqfDaOpU1aX4K3ZCgZDZD`,
      'Content-Type': 'application/json'
    }
  })
  .then((response: any) => {
    console.log('Message sent successfully:', response.data);
  })
  .catch((error: any) => {
    console.error('Error sending message:', error);
  });
};
