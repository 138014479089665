import { ToolsConfig, ToolName, FlightUpdateKey, CardName, FlightUpdateKeyCode } from '../../types/tools';
import { Coordinates } from '../../types';
import { updateCardData } from '../../utils/cardDataUtils';

//Toolsets equivale a la vista de http://localhost:5173/workflows/tools

enum Language {
  JAPANESE = 'ja',
  ENGLISH = 'en'
}

export const createToolsConfig = (
  setMemoryKv: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>,
  setCoords: React.Dispatch<React.SetStateAction<Coordinates | null>>,
  setMarker: React.Dispatch<React.SetStateAction<Coordinates | null>>,
  showDataInCards: (cardName: CardName, data: { [key: string]: any }) => void,
  sendMessageHandler: (fullName: string, origin_city: string, destination_city: string, departure_date: string, return_date: string, phonenumber: string, language: string) => void // Added handler pointer

): ToolsConfig => ({
  [ToolName.SET_MEMORY]: {
    name: ToolName.SET_MEMORY,
    description: 'Saves important data about the user into memory.',
    parameters: {
      type: 'object',
      properties: {
        key: {
          type: 'string',
          description: 'The key of the memory value. Always use lowercase and underscores, no other characters.',
        },
        value: {
          type: 'string',
          description: 'Value can be anything represented as a string',
        },
      },
      required: ['key', 'value'],
    },
    handler: async ({ key, value }: { [key: string]: any }) => {
      setMemoryKv((prevMemory) => ({
        ...prevMemory,
        [key]: value
      }));
      return { ok: true };
    }
  },

  [ToolName.UPDATE_FLIGHT]: {
    name: ToolName.UPDATE_FLIGHT,
    description: 'saves or updates any flight booking information like dates and cities',
    parameters: {
      type: 'object',
      properties: {
        key: {
          type: 'string',
          enum: Object.values(FlightUpdateKey),
          description: 'The flights options chosen by the user ongoing and return, departure and arrival date and city)',
        },
        value: {
          type: 'string',
          description: 'The new value of date (YYYY-MM-DD) or city',
        },
      },
      required: ['key', 'value'],
    },
    handler: async ({ key, value }: { key: FlightUpdateKey; value: string }) => {
      const updatedData = updateCardData(CardName.FLIGHT_DATES, { [key]: value });
      
      //showDataInCards(CardName.FLIGHT_DATES, { [key]: value });
      //console.log("old", { [key]: value });

      showDataInCards(CardName.FLIGHT_DATES, updatedData);
      console.log("new", updatedData);
      return { ok: true };
    }
  },

  [ToolName.UPDATE_FLIGHT_CODE]: {
    name: ToolName.UPDATE_FLIGHT_CODE,
    description: 'saves or updates or display on screen any flight booking information like dates and cities. ',
    parameters: {
      type: 'object',
      properties: {
        key: {
          type: 'string',
          enum: Object.values(FlightUpdateKeyCode),
          description: 'The key name of the flight options chosen by the user, dates, origin and destination',
        },
        value: {
          type: 'string',
          description: 'The new value of date (YYYY-MM-DD), city or IATA code',
        },
      },
      required: ['key', 'value'],
    },
    handler: async ({ key, value }: { key: FlightUpdateKeyCode; value: string }) => {
      const updatedData = updateCardData(CardName.FLIGHT_DATES_CODE, { [key]: value });
      showDataInCards(CardName.FLIGHT_DATES_CODE, updatedData);
      return { ok: true };
    }
  },

  // Weather Tool needs to return the weather data since it's fetching external info
  [ToolName.WEATHER]: {
    name: ToolName.WEATHER,
    description: 'Get weather for location',
    parameters: {
      type: 'object',
      properties: {
        lat: {
          type: 'number',
          description: 'Latitude coordinate',
        },
        lng: {
          type: 'number',
          description: 'Longitude coordinate',
        },
        location: {
          type: 'string',
          description: 'Location name',
        }
      },
      required: ['lat', 'lng', 'location']
    },
    handler: async ({ lat, lng, location }: { lat: number; lng: number; location: string }) => {
      setMarker({ lat, lng, location });
      setCoords({ lat, lng, location });
      
      const result = await fetch(
        `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lng}&current=temperature_2m,wind_speed_10m`
      );
      const json = await result.json();
      
      const temperature = {
        value: json.current.temperature_2m as number,
        units: json.current_units.temperature_2m as string,
      };
      const wind_speed = {
        value: json.current.wind_speed_10m as number,
        units: json.current_units.wind_speed_10m as string,
      };
      
      setMarker({ lat, lng, location, temperature, wind_speed });
      return json; // Weather tool needs to return data since it's fetching external info
    }
  },

  [ToolName.SEND_MESSAGE]: {
    name: ToolName.SEND_MESSAGE,
    description: 'Sends a message to a user\'s phone.',
    parameters: {
      type: 'object',
      properties: {
        fullName: {
          type: 'string',
          description: 'Full name of the passenger'
        },
        origin_city: {
          type: 'string',
          description: 'Origin city for the trip'
        },
        destination_city: {
          type: 'string',
          description: 'Destination city for the trip'
        },
        departure_date: {
          type: 'string',
          description: 'Date of departure in format YYYY-MM-DD'
        },
        return_date: {
          type: 'string',
          description: 'Date of return in format YYYY-MM-DD'
        },
        phonenumber: {
          type: 'string',
          description: 'Phone number to send the message to, E.164 format without plus (+)'
        },
        language: {
          type: 'string',
          enum: Object.values(Language),
          description: 'Language in which the message should be sent: "ja" for Japanese, "en" for any other language'
        }
      },
      required: ['fullName', 'origin_city', 'destination_city', 'departure_date', 'return_date', 'phonenumber', 'language'],
    },
    handler: async ({ fullName, origin_city, destination_city, departure_date, return_date, phonenumber, language }: { fullName: string; origin_city: string; destination_city: string; departure_date: string; return_date: string; phonenumber: string; language: string }) => {
      sendMessageHandler(fullName, origin_city, destination_city, departure_date, return_date, phonenumber, language);
      return { ok: true };
    }
  }
  ,

  [ToolName.SEND_MESSAGE_CODE]: {
    name: ToolName.SEND_MESSAGE_CODE,
    description: 'Sends booking information to a user\'s phone.',
    parameters: {
      type: 'object',
      properties: {
        fullName: {
          type: 'string',
          description: 'Full name of the passenger'
        },
        origin_city: {
          type: 'string',
          description: 'Origin city for the trip'
        },
        destination_city: {
          type: 'string',
          description: 'Destination city for the trip'
        },
        departure_date: {
          type: 'string',
          description: 'Date of departure in format YYYY-MM-DD',
        },

        return_date: {
            type: 'string',
            description: 'Date of return in format YYYY-MM-DD'
          },
        
        departure_code: {
          type: 'string',
          description: 'Departure Airport IATA Code'
        },
        return_code: {
          type: 'string',
          description: 'Return Airport IATA Code'
        },
        phonenumber: {
          type: 'string',
          description: 'Phone number to send the message to, E.164 format without plus (+)'
        },
        language: {
          type: 'string',
          enum: Object.values(Language),
          description: 'Language in which the message template should be sent: "ja" for Japanese, "en" for any other language'
        }
      },
      required: ['fullName', 'origin_city', 'destination_city', 'departure_date', 'return_date', 'phonenumber', 'language'],
    },
    handler: async ({ fullName, origin_city, destination_city, departure_date, return_date, phonenumber, language }: { fullName: string; origin_city: string; destination_city: string; departure_date: string; return_date: string; phonenumber: string; language: string }) => {
      sendMessageHandler(fullName, origin_city, destination_city, departure_date, return_date, phonenumber, language);
      return { ok: true };
    }
  },

  [ToolName.UPDATE_CHECKED_BAGS]: {
    name: ToolName.UPDATE_CHECKED_BAGS,
    description: 'saves or updates the number of checked bags',
    parameters: {
      type: 'object',
      properties: {
        checkedBag: {
          type: 'string',
          enum: ['1', '2'],
          description: 'The number of checked bags, can be "1" or "2". Default is "1"',
        },
      },
      required: ['checkedBag'], 
    },
    handler: async ({ checkedBag = '1' }: { checkedBag?: string }) => {
      showDataInCards(CardName.CHECKED_BAGS, { checkedBag });
      return { ok: true };
    }
  },

  [ToolName.SHOW_FLIGHT]: {
    name: ToolName.SHOW_FLIGHT,
    description: 'User want to see or watch flight information without updating or changing it.',
    parameters: {
      type: 'object',
      properties: {},
      required: [],
    },
    handler: async () => {
      showDataInCards(CardName.FLIGHT_DATES, {}); 
      return { ok: true };
    }
  },

  [ToolName.SHOW_CHECKED_BAGS]: {
    name: ToolName.SHOW_CHECKED_BAGS,
    description: 'User want to see or watch checked bags information without updating or changing it.',
    parameters: {
      type: 'object',
      properties: {},
      required: [],
    },
    handler: async () => {
      showDataInCards(CardName.CHECKED_BAGS, {});
      return { ok: true };
    }
  },

  [ToolName.CREATE_TICKET]: {
    name: ToolName.CREATE_TICKET,
    description: "Creates a new ticket in the system. Use this when a customer wants to register a new issue. Respond to the user before calling this tool with \"I'll create a ticket for you right away.\"",
    parameters: {
      type: 'object',
      properties: {
        fullName: {
          type: 'string',
          description: 'Full name of the customer',
        },
        phoneNumber: {
          type: 'string',
          description: 'Customer phone number',
        },
        issue: {
          type: 'string',
          description: 'Description of the problem',
        }
      },
      required: ['fullName', 'phoneNumber', 'issue'],
    },
    handler: async ({ fullName, phoneNumber, issue }: { fullName: string; phoneNumber: string; issue: string }) => {
      try {
        const response = await fetch('https://ntt-malasia-531584264755.us-central1.run.app/create-ticket', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({ 
            full_name: fullName,
            phone_number: phoneNumber,
            issue 
          })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        if (data.status === 'success') {
          return {
            status: 'success',
            ticketId: data.ticket_id,
            // created_at: data.ticket.created_at,
            message: `Ticket created successfully with ID: ${data.ticket_id}`
          };
        } else {
          throw new Error(data.message || 'Failed to create ticket');
        }
      } catch (error) {
        console.error('Error creating ticket:', error);
        return {
          status: 'error',
          message: error instanceof Error ? error.message : 'Failed to create ticket. Please try again.'
        };
      }
    }
  },

  [ToolName.CHECK_TICKET_STATUS]: {
    name: ToolName.CHECK_TICKET_STATUS,
    description: 'Checks the status of an existing ticket. Use this when a customer asks about their ticket status. Respond with "Let me check that ticket status for you" before calling the tool.',
    parameters: {
      type: 'object',
      properties: {
        ticketId: {
          type: 'string',
          description: 'Ticket ID to check',
        }
      },
      required: ['ticketId'],
    },
    handler: async ({ ticketId }: { ticketId: string }) => {
      try {
        const response = await fetch('https://ntt-malasia-531584264755.us-central1.run.app/check-ticket-status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ ticket_id: ticketId })
        });
        
        const data = await response.json();
        if (data.status === 'success') {
          return {
            status: 'success',
            ticketStatus: data.ticket.status,
            created_at: data.ticket.created_at,
            message: `Ticket ${ticketId} status: ${data.ticket.status}`
          };
        } else {
          throw new Error('Failed to check ticket status');
        }
      } catch (error) {
        return {
          status: 'error',
          message: 'Failed to check ticket status. Please try again.'
        };
      }
    }
  },

  [ToolName.ESCALATE_PROBLEM]: {
    name: ToolName.ESCALATE_PROBLEM,
    description: "Escalates an existing problem for priority attention. Use this when a customer is unsatisfied or needs urgent attention. Tell the user \"I'll escalate this issue right away\" before calling.",
    parameters: {
      type: 'object',
      properties: {
        fullName: {
          type: 'string',
          description: 'Nombre completo del cliente',
        },
        phoneNumber: {
          type: 'string',
          description: 'Número de teléfono del cliente',
        },
        reason: {
          type: 'string',
          description: 'Razón de la escalación',
        }
      },
      required: ['fullName', 'phoneNumber', 'reason'],
    },
    handler: async ({ fullName, phoneNumber, reason }: { fullName: string; phoneNumber: string; reason: string }) => {
      const response = await fetch('https://ntt-malasia-531584264755.us-central1.run.app/escalate-problem', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ fullName, phoneNumber, reason })
      });
      return await response.json();
    }
  },

  [ToolName.CHECK_POLICY_STATUS]: {
    name: ToolName.CHECK_POLICY_STATUS,
    description: "Verifies the data of an insurance policy. Use this when a customer wants to know about their policy",
    parameters: {
      type: 'object',
      properties: {
        fullName: {
          type: 'string',
          description: 'Nombre completo del cliente',
        },
        policyNumber: {
          type: 'string',
          description: 'Número de póliza a consultar',
        }
      },
      required: ['fullName', 'policyNumber'],
    },
    handler: async ({ fullName, policyNumber }: { fullName: string; policyNumber: string }) => {
      try {
        const response = await fetch('https://ntt-malasia-531584264755.us-central1.run.app/check-policy-status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({ fullName, policyNumber })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.status === 'success') {
          console.log(data);
          setMemoryKv(prevMemory => ({
            ...prevMemory,
            policy_details: data
          }));

          return {
            status: 'success',
            policy: data,
            message: `Policy ${policyNumber} is ${data}`
          };
        } else {
          throw new Error(data.message || 'Failed to check policy status');
        }
      } catch (error) {
        console.error('Error checking policy:', error);
        return {
          status: 'error',
          message: error instanceof Error ? error.message : 'Failed to check policy status. Please try again.'
        };
      }
    }
  },
});