import React, { useRef, useEffect } from 'react';
import { X } from 'react-feather';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import './MessagesChat.scss';

interface MessagesChatProps {
  items: ItemType[];
  deleteConversationItem: (id: string) => void;
}

export const MessagesChat: React.FC<MessagesChatProps> = ({
  items,
  deleteConversationItem,
}) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [items]);

  return (
    <div ref={chatContainerRef} className="messages-chat-container">
      {!items.length && (
        <div className="text-gray-500 text-center text-xs awaiting-connection">
          awaiting connection...
        </div>
      )}
      
      {items
        .filter(item => item.role === 'assistant')
        .map((item, index) => (
          <div
            key={item.id}
            className={`flex justify-start mb-2`}
          >
            <div 
              className={`relative rounded-lg p-2 message-item assistant`}
              style={{
                opacity: Math.max(0.3, 1 - (items.length - index - 1) * 0.15),
              }}
            >
              {/* Delete button */}
              <button
                className="absolute -right-2 -top-2 p-1 rounded-full bg-gray-700 
                           text-white opacity-0 hover:opacity-100 transition-opacity"
                onClick={() => deleteConversationItem(item.id)}
              >
                <X size={8} />
              </button>

              <div className="flex items-center gap-1">
                {/* Message content */}
                <div className="flex-grow">
                  {/* Assistant message */}
                  {item.formatted.transcript || item.formatted.text ? (
                    <p>
                      {item.formatted.transcript || item.formatted.text}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};