import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "../../../components/ui/card"
import { Button } from "../../../components/ui/button"
import { RefreshCcw, CheckCircle2, AlertCircle, Clock, Plane, Calendar, Users, MapPin, CreditCard, Edit } from 'lucide-react'

interface InfoPoint {
  id: string;
  category: string;
  key: string;
  value: string;
  status?: 'success' | 'pending' | 'warning';
  icon?: JSX.Element;
  timestamp?: Date;
}

interface InformationSummaryProps {
  title?: string;
  description?: string;
  infoPoints?: InfoPoint[];
  emptyMessage?: string;
  editButtonText?: string;
  confirmButtonText?: string;
  confirmEditText?: string;
  onEdit?: () => void;
  onConfirm?: () => void;
  showIcons?: boolean;
  showTimestamp?: boolean;
  categorized?: boolean;
}

export default function InformationSummary({
  title = "Resumen de Información",
  description = "Información procesada por el asistente",
  infoPoints = [
    {
      id: "1",
      category: "Vuelo",
      key: "Destino",
      value: "Nueva York",
      status: "success",
      icon: <Plane className="h-4 w-4" />,
      timestamp: new Date()
    },
    {
      id: "2",
      category: "Vuelo",
      key: "Hora de salida",
      value: "10:00 AM",
      status: "success",
      icon: <Clock className="h-4 w-4" />,
      timestamp: new Date()
    },
    {
      id: "3",
      category: "Reserva",
      key: "Fecha",
      value: "15 de Mayo, 2024",
      status: "success",
      icon: <Calendar className="h-4 w-4" />,
      timestamp: new Date()
    },
    {
      id: "4",
      category: "Reserva",
      key: "Pasajeros",
      value: "2 adultos",
      status: "success",
      icon: <Users className="h-4 w-4" />,
      timestamp: new Date()
    },
    {
      id: "5",
      category: "Ubicación",
      key: "Origen",
      value: "Madrid",
      status: "success",
      icon: <MapPin className="h-4 w-4" />,
      timestamp: new Date()
    },
    {
      id: "6",
      category: "Pago",
      key: "Método",
      value: "Tarjeta de crédito",
      status: "pending",
      icon: <CreditCard className="h-4 w-4" />,
      timestamp: new Date()
    }
  ],
  emptyMessage = "No hay información procesada",
  editButtonText = "Modificar Itinerario",
  confirmButtonText = "Confirmar Datos",
  confirmEditText = "¿Estás seguro de que deseas modificar el itinerario?",
  onEdit = () => {},
  onConfirm = () => {},
  showIcons = true,
  showTimestamp = false,
  categorized = true,
}: InformationSummaryProps) {
  const handleEdit = () => {
    if (window.confirm(confirmEditText)) {
      onEdit();
    }
  };

  const getStatusIcon = (status: string = 'success') => {
    switch (status) {
      case 'success':
        return <CheckCircle2 className="h-4 w-4 text-green-500" />;
      case 'warning':
        return <AlertCircle className="h-4 w-4 text-yellow-500" />;
      case 'pending':
        return <Clock className="h-4 w-4 text-blue-500" />;
      default:
        return null;
    }
  };

  const groupedInfoPoints = categorized
    ? infoPoints.reduce((acc, point) => {
        const category = point.category || 'Otros';
        return {
          ...acc,
          [category]: [...(acc[category] || []), point],
        };
      }, {} as { [key: string]: InfoPoint[] })
    : { 'Información': infoPoints };

  return (
    <Card className="w-full mx-auto flex flex-col content-card-background">
      <CardHeader className="flex-shrink-0 border-b p-4">
        <CardTitle className="flex items-center gap-2 justify-center">
          {title}
        </CardTitle>
        <p className="text-sm text-muted-foreground">{description}</p>
      </CardHeader>
      <div className="flex-1 overflow-y-auto">
        <CardContent className="p-6">
          {infoPoints.length === 0 ? (
            <div className="text-center text-muted-foreground py-4">
              {emptyMessage}
            </div>
          ) : (
            <div className="space-y-6">
              {Object.entries(groupedInfoPoints).map(([category, points]) => (
                <div key={category} className="space-y-3">
                  {categorized && (
                    <h3 className="text-sm font-medium text-muted-foreground">
                      {category}
                    </h3>
                  )}
                  <div className="space-y-2">
                    {points.map((point) => (
                      <div
                        key={point.id}
                        className="flex items-center gap-3 p-2 rounded-lg hover:bg-muted/50 transition-colors min-w-0"
                      >
                        {showIcons && point.icon && (
                          <div className="flex-shrink-0 text-muted-foreground">
                            {point.icon}
                          </div>
                        )}
                        <div className="flex-1 min-w-0">
                          <div className="flex items-center gap-2 flex-wrap">
                            <span className="text-sm font-medium truncate">{point.key}:</span>
                            <span className="text-sm break-words">{point.value}</span>
                          </div>
                          {showTimestamp && point.timestamp && (
                            <span className="text-xs text-muted-foreground block">
                              {new Date(point.timestamp).toLocaleTimeString()}
                            </span>
                          )}
                        </div>
                        <div className="flex-shrink-0">
                          {getStatusIcon(point.status)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </CardContent>
      </div>
      {infoPoints.length > 0 && (
        <CardFooter className="p-4 flex-shrink-0 border-t">
          <div className="flex flex-col sm:flex-row justify-between w-full gap-4">
            <Button
              variant="outline"
              className="flex items-center gap-2 w-full sm:w-auto"
              onClick={handleEdit}
            >
              <Edit className="h-4 w-4" />
              <span>{editButtonText}</span>
            </Button>
            <Button
              variant="default"
              className="flex items-center gap-2 w-full sm:w-auto"
              onClick={onConfirm}
            >
              <CheckCircle2 className="h-4 w-4" />
              <span>{confirmButtonText}</span>
            </Button>
          </div>
        </CardFooter>
      )}
    </Card>
  );
} 