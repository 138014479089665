import { SyntphonyPlayHT } from './syntphony-playht';
import { useState, useEffect } from 'react';
import { fetchAgentNames, fetchAgentById } from '../services/agentDirectoryService';
import { VirtualAgent } from '../data/virtualAgents';
import { AgentCustomUI } from '../types/agent-ui';
import { useParams, useSearchParams } from 'react-router-dom';
import { agentsData } from '../data/agentsData';

export function AgentPlay() {
  const { agentId } = useParams();
  const [searchParams] = useSearchParams();
  const [agentConfig, setAgentConfig] = useState<VirtualAgent | null>(null);
  const [loading, setLoading] = useState(true);
  const isWidget = searchParams.get('widget') === 'true';
  
  useEffect(() => {
    const loadAgent = async () => {
      try {
        const agent = await fetchAgentById(agentId as string);
        console.log('🔍 Agent found:', agent);
        if (agent) {
          // Find matching agent in agentsData to get tools
          const staticAgent = agentsData.find(
            (staticAgent) => staticAgent.id === agentId
          );
          
          // Merge database config with static tools
          setAgentConfig({
            ...agent,
            tools: staticAgent?.tools || []
          });
        } else {
          setAgentConfig(null);
        }
      } catch (error) {
        console.error('Error loading agent:', error);
        const fallbackAgent = agentsData.find(
          (agent) => agent.id === agentId
        );
        setAgentConfig(fallbackAgent as VirtualAgent || null);
      } finally {
        setLoading(false);
      }
    };

    loadAgent();
  }, [agentId]);

  if (loading) return (
    <div className="min-h-screen bg-slate-950 p-6 flex flex-col items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
      <div className="text-white mt-4">Loading agent...</div>
    </div>
  );

  if (!agentConfig) return (
    <div className="min-h-screen bg-slate-950 p-6 flex flex-col items-center justify-center">
      <div className="text-red-500 mb-4">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="h-12 w-12" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
          />
        </svg>
      </div>
      <div className="text-white text-lg">Agent not found</div>
    </div>
  );

  const customUI: AgentCustomUI = {
    centerContent: isWidget ? null : <div>{agentConfig.name}</div>,
  };

  return <SyntphonyPlayHT 
    config={agentConfig} 
    customUI={customUI}
  />;
}
