import React from 'react';
// import './Logo.css';
import syntphonyLogo from '../../images/syntphonyLogo.png';

const Logo: React.FC = () => {
  return (
    <div className="logo-container">
      <img 
        src={syntphonyLogo}
        alt="Syntphony Logo" 
        className="logo-image"
      />
    </div>
  );
};

export default Logo; 