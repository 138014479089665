import { Backpack, Briefcase, LuggageIcon } from 'lucide-react'
import { Card, CardContent } from "../../ui/card"

interface BaggageOptionsProps {
  checkedBag?: '1' | '2';
}

export default function BaggageOptions({ checkedBag = '1' }: BaggageOptionsProps) {
  const checkedBagsNumber = parseInt(checkedBag as string, 10);

  return (
    <div className="w-full max-w-md mx-auto p-2 space-y-2">
      <Card>
        <CardContent className="flex items-start gap-3 p-4">
          <Backpack className="w-6 h-6 text-primary shrink-0" />
          <div className="space-y-1">
            <h3 className="font-semibold">Personal Item</h3>
            <p className="text-sm">1 piece per person.</p>
            <p className="text-xs text-muted-foreground">
              Must be placed under the seat in front of you
            </p>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardContent className="flex items-start gap-3 p-4">
          <Briefcase className="w-6 h-6 text-primary shrink-0" />
          <div className="space-y-1">
            <h3 className="font-semibold">Carry-on Baggage</h3>
            <p className="text-sm">1 piece per person.</p>
            <p className="text-xs text-muted-foreground">
              Each piece cannot exceed 55 x 40 x 25 cm in size. Total dimensions (length + width + height) of each piece cannot exceed 115 cm.
            </p>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardContent className="flex items-start gap-3 p-4">
          <div className="flex items-center gap-1">
            <LuggageIcon className="w-6 h-6 text-primary shrink-0" />
            {checkedBagsNumber === 2 && <LuggageIcon className="w-6 h-6 text-primary shrink-0" />}
          </div>
          <div className="space-y-1">
            <h3 className="font-semibold">Checked Baggage</h3>
            <p className="text-sm">{checkedBagsNumber} piece{checkedBagsNumber > 1 ? 's' : ''} per person, 23 kg per piece.</p>
            <p className="text-xs text-muted-foreground">
              Total dimensions (length + width + height) of each piece cannot exceed 203 cm.
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

