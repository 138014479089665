"use client"

import { useState, useEffect } from "react"
import { Card, CardContent } from "../../../components/ui/card"
import { Button } from "../../../components/ui/button"
import { format, addDays, parseISO } from "date-fns"
import { CardName } from '../../../types/tools';

interface DateSelectorProps {
  departureDate?: string;
  returnDate?: string;
  origin?: string;
  destination?: string;
}

export default function DateSelector({ departureDate, returnDate, origin, destination }: DateSelectorProps) {
  const [departure, setDepartureDate] = useState<Date>(departureDate ? parseISO(departureDate) : new Date());
  const [returnD, setReturnDate] = useState<Date>(returnDate ? parseISO(returnDate) : addDays(new Date(), 7));
  const [travelData, setTravelData] = useState<{ origin: string; destination: string }>({
    origin: origin || "-",
    destination: destination || "-"
  });

  useEffect(() => {
    const cardData = sessionStorage.getItem('cardData');
    if (cardData) {
      const parsedData = JSON.parse(cardData);
      const flightDates = parsedData[CardName.FLIGHT_DATES];
      
      if (flightDates) {
        if (flightDates.departure_date) {
          setDepartureDate(parseISO(flightDates.departure_date));
        }
        if (flightDates.return_date) {
          setReturnDate(parseISO(flightDates.return_date));
        }
        setTravelData({
          origin: flightDates.origin_city || "-",
          destination: flightDates.destination_city || "-"
        });
      }
    }
  }, []);

  useEffect(() => {
    if (departureDate) {
      setDepartureDate(parseISO(departureDate));
    }
    if (returnDate) {
      setReturnDate(parseISO(returnDate));
    }
    if (origin || destination) {
      setTravelData({
        origin: origin || travelData.origin,
        destination: destination || travelData.destination
      });
    }
  }, [departureDate, returnDate, origin, destination]);

  const formatDate = (date: Date) => {
    return format(date, "EEE, MMM d, yyyy")
  }

  const daysBetween = Math.ceil((returnD.getTime() - departure.getTime()) / (1000 * 3600 * 24))

  return (
    <Card className="w-full mx-auto bg-card/80 backdrop-blur-lg text-card-foreground shadow-lg content-card-background">
      <CardContent className="p-4">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h3 className="text-sm font-medium text-black">From</h3>
            <p className="text-lg text-black">{travelData.origin}</p>
          </div>
          <div className="text-right">
            <h3 className="text-sm font-medium text-black">To</h3>
            <p className="text-lg text-black">{travelData.destination}</p>
          </div>
        </div>
        <div className="space-y-2 mb-4">
          <Button
            variant="outline"
            className="w-full justify-start text-left font-normal"
          >
            <div>
              <div className="text-xs text-muted-foreground">Departure</div>
              <div className="text-sm text-black">{formatDate(departure)}</div>
            </div>
          </Button>
          <Button
            variant="outline"
            className="w-full justify-start text-left font-normal"
          >
            <div>
              <div className="text-xs text-muted-foreground">Return</div>
              <div className="text-sm text-black">{formatDate(returnD)}</div>
            </div>
          </Button>
        </div>
        <div className="text-center">
          <p className="text-sm font-medium text-black">{daysBetween} day{daysBetween !== 1 ? 's' : ''}</p>
          <p className="text-xs text-muted-foreground">Trip duration</p>
        </div>
      </CardContent>
    </Card>
  )
}