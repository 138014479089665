import { CardName } from '../types/tools';

export const updateCardData = (cardName: CardName, newData: any) => {
  const existingData = sessionStorage.getItem('cardData');
  const parsedData = existingData ? JSON.parse(existingData) : {};
  
  parsedData[cardName] = {
    ...(parsedData[cardName] || {}),
    ...newData
  };
  
  sessionStorage.setItem('cardData', JSON.stringify(parsedData));
  return parsedData[cardName];
}; 