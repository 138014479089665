import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { AgentPlay } from './pages/agent-play';

export function App() {
  return (
    <BrowserRouter>
      <div className="app">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:agentId" element={<AgentPlay />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}
