import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "../../../components/ui/card"
import { Button } from "../../../components/ui/button"
import { Trash2, MessageSquare } from 'lucide-react'
import { format } from 'date-fns'

interface Conversation {
  id: string;
  timestamp: Date;
  message: string;
  isUser: boolean;
  summary?: string;
}

interface ConversationHistoryProps {
  title?: string;
  description?: string;
  conversations?: Conversation[];
  emptyMessage?: string;
  deleteButtonText?: string;
  confirmDeleteText?: string;
  onDelete?: () => void;
  dateFormat?: string;
}

export default function ConversationHistory({
  title = "Historial de Conversaciones",
  description = "Revisa tus interacciones previas",
  conversations = [],
  emptyMessage = "No hay conversaciones previas",
  deleteButtonText = "Borrar Historial",
  confirmDeleteText = "¿Estás seguro de que deseas borrar todo el historial?",
  onDelete = () => {},
  dateFormat = "dd MMM yyyy HH:mm"
}: ConversationHistoryProps) {
  const handleDelete = () => {
    if (window.confirm(confirmDeleteText)) {
      onDelete();
    }
  };

  return (
    <Card className="w-full mx-auto content-card-background">
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <p className="text-sm text-muted-foreground">{description}</p>
      </CardHeader>
      <CardContent className="p-6">
        {conversations.length === 0 ? (
          <div className="text-center text-muted-foreground py-8">
            {emptyMessage}
          </div>
        ) : (
          <div className="space-y-4">
            {conversations.map((conv) => (
              <div
                key={conv.id}
                className={`flex items-start space-x-4 p-4 rounded-lg ${
                  conv.isUser ? 'bg-primary/10' : 'bg-secondary/10'
                }`}
              >
                <MessageSquare className={`h-5 w-5 mt-1 ${
                  conv.isUser ? 'text-primary' : 'text-secondary'
                }`} />
                <div className="flex-grow">
                  <div className="flex justify-between items-start">
                    <p className="text-sm font-medium">
                      {conv.isUser ? 'Tú' : 'Asistente'}
                    </p>
                    <span className="text-xs text-muted-foreground">
                      {format(new Date(conv.timestamp), dateFormat)}
                    </span>
                  </div>
                  <p className="text-sm mt-1">{conv.message}</p>
                  {conv.summary && (
                    <p className="text-xs text-muted-foreground mt-1">
                      {conv.summary}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </CardContent>
      {conversations.length > 0 && (
        <CardFooter className="p-6">
          <Button
            variant="destructive"
            className="ml-auto flex items-center space-x-2"
            onClick={handleDelete}
          >
            <Trash2 className="h-4 w-4" />
            <span>{deleteButtonText}</span>
          </Button>
        </CardFooter>
      )}
    </Card>
  )
} 