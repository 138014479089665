import { ToolName } from '../types/tools';
import { VirtualAgent } from './virtualAgents';

//esto equivale a http://localhost:5173/agents/directory
export const agentsData: Pick<VirtualAgent, 'id' | 'tools' | 'logo'>[] = [
  
  {
    id: '12e3c277-decd-4b92-a63b-081c11e33b3d',
    tools: [
      ToolName.UPDATE_FLIGHT,
    ToolName.WEATHER,
    ToolName.SEND_MESSAGE,
    ToolName.UPDATE_CHECKED_BAGS
  ],
},
  

  {
    id: 'e2e3c277-decd-4b92-a63b-081c11e33b3d',
    tools: [
      ToolName.UPDATE_FLIGHT,
      ToolName.WEATHER,
      ToolName.SEND_MESSAGE,
      ToolName.UPDATE_CHECKED_BAGS
    ],
  },
  {
    id: '81fd911e-d8f4-4bee-994c-8f5e83aa678b',
    tools: [
      ToolName.UPDATE_FLIGHT,
      ToolName.WEATHER,
      ToolName.SEND_MESSAGE,
      ToolName.UPDATE_CHECKED_BAGS
    ],
  },
  {
    id: 'b4496f24-6142-434d-86f0-8c1c1f8a8140',
    tools: [ToolName.SET_MEMORY],
    logo: 'https://companieslogo.com/img/orig/9432.T-f5c153d4.png?t=1720244493'
  },
  {
    id: '5622f1b6-b452-4284-91a0-22a571237ea1',
    tools: [ToolName.SET_MEMORY],
  },
  {
    id: '0171985a-ee89-4134-9091-0e10c0cb8000',
    tools: [ToolName.SET_MEMORY],
    logo: 'https://companieslogo.com/img/orig/9432.T-f5c153d4.png?t=1720244493'
  },
  {
    id: '88c3ee81-e848-4655-8951-53e35e04073f',
    tools: [
      ToolName.CREATE_TICKET,
      ToolName.CHECK_TICKET_STATUS,
      ToolName.ESCALATE_PROBLEM,
      ToolName.CHECK_POLICY_STATUS
    ],
  }
]; 