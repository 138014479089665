"use client"

import { useState, useEffect } from "react"
import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/card"
import { Button } from "../../../components/ui/button"
import { MapPin, Compass, Loader2 } from 'lucide-react'
import { Map, Marker } from 'pigeon-maps'

interface LocationMapProps {
  title?: string;
  description?: string;
  defaultLocation?: [number, number];
  defaultZoom?: number;
  showCurrentLocation?: boolean;
  onLocationSelect?: (location: { lat: number; lng: number }) => void;
}

interface MapClickEvent {
  latLng: [number, number];
  pixel: [number, number];
  event: MouseEvent;
}

export default function LocationMap({
  title = "Ubicación Actual",
  description = "Selecciona o encuentra tu ubicación en el mapa",
  defaultLocation = [40.416775, -3.703790], // Madrid por defecto
  defaultZoom = 13,
  showCurrentLocation = true,
  onLocationSelect = () => {},
}: LocationMapProps) {
  const [location, setLocation] = useState<[number, number]>(defaultLocation)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const getCurrentLocation = () => {
    setIsLoading(true)
    setError(null)

    if (!navigator.geolocation) {
      setError("Tu navegador no soporta geolocalización")
      setIsLoading(false)
      return
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const newLocation: [number, number] = [
          position.coords.latitude,
          position.coords.longitude
        ]
        setLocation(newLocation)
        onLocationSelect({ lat: newLocation[0], lng: newLocation[1] })
        setIsLoading(false)
      },
      (error) => {
        setError("No se pudo obtener tu ubicación")
        setIsLoading(false)
      }
    )
  }

  useEffect(() => {
    if (showCurrentLocation) {
      getCurrentLocation()
    }
  }, [])

  return (
    <Card className="w-full mx-auto content-card-background">
      <CardHeader className="flex-shrink-0 border-b">
        <CardTitle className="flex items-center gap-2 justify-center">
          <MapPin className="h-5 w-5" />
          {title}
        </CardTitle>
        <p className="text-sm text-muted-foreground">{description}</p>
      </CardHeader>
      <CardContent className="p-4">
        <div className="space-y-4">
          <div className="h-[300px] relative rounded-lg overflow-hidden border">
            <Map
              height={300}
              center={location}
              zoom={defaultZoom}
              onClick={({ latLng }: MapClickEvent) => {
                setLocation([latLng[0], latLng[1]])
                onLocationSelect({ lat: latLng[0], lng: latLng[1] })
              }}
            >
              <Marker width={50} anchor={location} />
            </Map>
          </div>

          <div className="flex justify-between items-center">
            <div className="flex-1">
              {error && (
                <p className="text-sm text-destructive">{error}</p>
              )}
              <p className="text-sm text-muted-foreground">
                Lat: {location[0].toFixed(6)}, Lng: {location[1].toFixed(6)}
              </p>
            </div>
            <Button
              variant="outline"
              size="sm"
              onClick={getCurrentLocation}
              disabled={isLoading}
              className="flex items-center gap-2"
            >
              {isLoading ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <Compass className="h-4 w-4" />
              )}
              Mi Ubicación
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  )
} 