import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchAgentNames } from '../services/agentDirectoryService';
import { VirtualAgent } from '../data/virtualAgents';
import { Card } from '../components/ui/card';
import LogoSyntphony from '../components/Logo/Logo';
import { agentsData } from '../data/agentsData';
import './HomePage.scss';

export function HomePage() {
  const navigate = useNavigate();
  const [agents, setAgents] = useState<VirtualAgent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadAgents = async () => {
      try {
        // console.log('🔄 Iniciando carga de agentes...');
        const teamId = '95dc5dd0-2f31-405b-84f5-9db7b7887aa4';
        const agentData = await fetchAgentNames(teamId);
        // console.log('📊 Datos recibidos:', agentData);
        setAgents(agentData);
      } catch (err) {
        console.error('❌ Error loading agents:', err);
        setAgents(agentsData as VirtualAgent[]);
      } finally {
        setLoading(false);
      }
    };

    loadAgents();
  }, []);

  if (loading) return (
    <div className="min-h-screen bg-slate-950 p-6 flex flex-col items-center justify-center">
      <div className="mb-16">
        <LogoSyntphony />
      </div>
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
      <div className="text-white mt-4">Loading agents...</div>
    </div>
  );

  const handleVirtualAssistantClick = () => {
    navigate('/virtual-assistant');
  };

  return (
    <div className="min-h-screen bg-slate-950 p-6 flex flex-col items-center justify-center">
      <div className="w-full max-w-md space-y-8">
        <div className="mb-16">
          <LogoSyntphony />
        </div>
        <div className="space-y-4">
          {agents.map((agent) => {
            const IconComponent = agent.icon;
            
            return (
              <Card
                key={agent.id}
                className="bg-slate-900/50 border-slate-800 hover:bg-slate-900 transition-colors cursor-pointer group"
                onClick={agent.id === 'chatbot' ? handleVirtualAssistantClick : undefined}
              >
                <Link 
                  to={agent.id}
                  className="w-full p-4 flex items-center gap-4 text-left"
                  aria-label={`Select ${agent.name} assistant`}
                >
                  <div 
                    className={`p-2 rounded-lg bg-slate-800 group-hover:scale-110 transition-transform`}
                  >
                    {IconComponent && <IconComponent className="w-5 h-5" style={{ color: agent.color }} />}
                  </div>
                  <div>
                    <h2 className="font-medium text-white">{agent.name}</h2>
                    <p className="text-sm text-slate-400">{agent.description}</p>
                  </div>
                </Link>
              </Card>
            );
          })}
        </div>
      </div>
    </div>
  );
} 