import { Card, CardContent, CardFooter } from "../../../components/ui/card"
import { Button } from "../../../components/ui/button"

interface Terms2ButtonsProps {
  title?: string;
  content?: string;
  cancelText?: string;
  acceptText?: string;
  onCancel?: () => void;
  onAccept?: () => void;
}

export default function Terms2Buttons({
  title = "Terms and Conditions",
  content = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pulvinar risus non risus hendrerit venenatis. Pellentesque sit amet hendrerit risus, sed porttitor quam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pulvinar risus non risus hendrerit venenatis. Pellentesque sit amet hendrerit risus, sed porttitor quam. Magna exercitation reprehenderit magna aute tempor cupidatat consequat elit dolor adipisicing. Mollit dolor eiusmod sunt ex incididunt cillum quis. Velit duis sit officia eiusmod Lorem aliqua enim laboris do dolor eiusmod. Et mollit incididunt nisi consectetur esse laborum eiusmod pariatur proident Lorem eiusmod et. Culpa deserunt nostrud ad veniam.",
  cancelText = "Cancel",
  acceptText = "Accept",
  onCancel = () => {},
  onAccept = () => {}
}: Terms2ButtonsProps) {
  return (
    <Card className="w-full mx-auto content-card-background">
      <CardContent className="p-6">
        <h2 className="text-xl font-semibold mb-4">{title}</h2>
        <p className="text-sm text-muted-foreground mb-4">
          {content}
        </p>
      </CardContent>
      <CardFooter className="flex justify-end space-x-4 p-6">
        <Button variant="outline" onClick={onCancel}>{cancelText}</Button>
        <Button onClick={onAccept}>{acceptText}</Button>
      </CardFooter>
    </Card>
  )
}